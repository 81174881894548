import React, { FC, useRef, useEffect,useState} from "react";
// layout
import Layout from "../../components/layout";
// import SectionForm from "../../components/PdfGenerator/sectionForm";
// import SectionSteps from "../../components/PdfGenerator/sectionSteps";
// import SectionPros from "../../components/PdfGenerator/sectionPros";
// import SectionH2h from "../../components/PdfGenerator/sectionHome2home";
import igemoLogo from "../../assets/logo/g10.svg";
import PdfService from '../../api/Pdf'
import {useParams, useSearchParams} from "react-router-dom";
import '../../styles/pdf.css'

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl'  // without this line it didn't work
moment.locale('nl')


interface PdfGeneratorInterface {}

const PdfGenerator: FC<PdfGeneratorInterface> = () => {

    const { hash} = useParams<string>();
    const [data, setData] = useState<string | TrustedHTML>("")

console.log("searchParams",hash)

  useEffect(() => {
    (async () => {
      if(hash){
          const resp = await PdfService.getDom(hash)
          const { data } = resp.data
          // console.log("==>",JSON.parse(data.pdfDom))
          setData(JSON.parse(data.pdfDom));
      }
    })()
  }, [hash])


  return (
      <>
        <div className="w-full mx-auto p-4" style={{ pageBreakInside: "avoid"}}>
        <div className="flex">
          {/*<div className="grow-0 flex justify-between items-center w-[550px]">*/}
          {/*  /!*<img src={process.env.REACT_APP_STORAGE_URL + '/uploads/files/pio.png'} className="h-[50px] object-fit cursor-pointer" />*!/*/}
          {/*  /!*<img src={process.env.REACT_APP_STORAGE_URL + '/uploads/files/stronghouse.e8288a74c64d8fdc4f0d.png'} className="h-[50px] object-fit cursor-pointer" />*!/*/}
          {/*  /!*<img src={process.env.REACT_APP_STORAGE_URL + '/uploads/files/stekr.png'} className="h-[50px] object-fit cursor-pointer" />*!/*/}
          {/*  /!*  <div className="flex items-center flex-col">*!/*/}
          {/*  /!*      <img src={process.env.REACT_APP_STORAGE_URL + '/uploads/files/gent.png'} className="h-[50px] object-fit cursor-pointer" />*!/*/}
          {/*  /!*      <p className = "font-Source text-[10px] text-gray-700">met steun van</p>*!/*/}
          {/*  /!*  </div>*!/*/}
          {/*</div>*/}
          <div className="grow"></div>
          <div className="grow-0 items-center flex">
            Gemaakt op <Moment format="LL" date={new Date()} className = "ml-[5px]" />
          </div>
        </div>
        </div>
        <div className="pdfContainer" style={{ pageBreakInside: "auto"}} dangerouslySetInnerHTML={{__html:  data }}>
        </div>
          <div className="flex justify-center items-center m-[2%] flex-col">
              <p className = "font-Source text-[20px] text-black m-[20px]">Dit rapport werd mogelijk gemaakt dankzij de steun van</p>
              <div className="grow-0 flex items-center">
                  <img src={process.env.REACT_APP_STORAGE_URL + '/uploads/files/fosster.png'} className="h-[100px] object-fit cursor-pointer" />
                  <img src={process.env.REACT_APP_STORAGE_URL + '/uploads/files/gent.png'} className="h-[100px] object-fit cursor-pointer" />
              </div>
          </div>
        {/* <SectionH2h /> */}
      </>
  );
};

export default PdfGenerator;
