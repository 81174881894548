import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import TitleBar from "../titleBar";
import SubmitBox from "../submitBox";
import InputArea from "../inputArea";
import axios from "axios";
import { getLocalStateValue } from "../../../utils/helpers";
import { parseRate, getCalculationPayload } from '../../../utils/resultActions'
import InfoModal from "../infoModal";
import { GetAPIService, PostAPIService } from "../../../context/services";

type HouseEnergyProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    userId?: string,
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

type Parameter = {
    name: string,
    value: number
}

const HouseEnergy = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, userId, modalInformation }: HouseEnergyProps) => {
    const [ heatValue, setHeatValue ] = useState<string>("0");
    const [ electrictyValue, setElectrictyValue ] = useState<string>("0");
    const [ solarValue, setSolarValue ] = useState<string>("0");
    const [ parameters, setParameters ] = useState<Parameter[]>([]);
    const [ modalState, setModalState ] = useState(false);
    const localStateJaar = getLocalStateValue("2");

    const navigate = useNavigate();

    // get the parameters on mount
    useEffect(() => {
        getParameters();
    }, [])

    // get the energy values after parameters are set
    useEffect(() => {
        if(parameters.length > 0) {
            getEnergyValues();
        }
    }, [parameters])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const onClick = () => {
        const question = "energieverbruiken-productie";
        const calclatedAnswer = `{\"elektriciteitsverbruik\":${electrictyValue},\"aardgasverbruik\":${heatValue},\"zonnepanelen\":${solarValue},\"energieproductie\":30}`
        updateAnswer(question || "", calclatedAnswer || "", localStateJaar.answerNo == 4 ?  "/vraag/vocht" : pageData?.next_url);
    }

    const enableButton = () => {
        if (parseInt(heatValue) >= 0 && parseInt(electrictyValue) >= 0 && parseInt(solarValue) >= 0) {
            return true;
        }
        return false;
    }

    // get the parameter object from the parameters array
    function getParameter(paramName: string) {
        const paramValue = parameters.find(param => param.name === paramName);
        return paramValue || null;
    }

    // the api call to get the parameters
    function getParameters() {
        GetAPIService('parameters').then(response => {
            const parameterData = response.data.data;
            if(parameterData[0]) {
                setParameters(parameterData[0]);
            }
        }).catch(error => console.log(error))
    }

    function getAnswerSixValue () {
        const answerSix = getLocalStateValue("6");
        const answerSeven = getLocalStateValue("7");
        const { verwarming } = answerSix;
        const { answerNo } = answerSeven;
        let answerValue = 0;
        if(verwarming === "gas") {
            answerValue = 0;
        }
        else if(verwarming === "elektriciteit") {
            if(answerNo === 0) {
                answerValue = 4;
            }
            else {
                answerValue = 5;
            }
        }
        else if(verwarming === "stookolie") {
            answerValue = 10;
        }
        else {
            answerValue = 14;
        }
        console.log("getAnswerSixValue",answerValue,answerNo,parseInt(answerNo + 1))

        return answerValue + parseInt(answerNo) + 1;
    }

    // the api call to get the energy values
    const getEnergyValues = async () => {
        if(userId) {
            let answers: any = {};
            const localState = JSON.parse(localStorage.getItem("localState") || "{}");
            const payloadGen = await  getCalculationPayload(localState);

                console.log("payload",payloadGen)
            // for(let key in localState) {
            //     if(parseInt(key) <= 6 && parseInt(key) !== 4) {
            //         if(parseInt(key) === 6) {
            //             answers[key] = getAnswerSixValue();
            //         }
            //         else {
            //             answers[key] = parseInt(localState[key].answerNo) + 1;
            //         }
            //     }
            //     else if(parseInt(key) >= 8 && parseInt(key) <= 21) {
            //         answers[(parseInt(key) - 1).toString()] = parseInt(localState[key].answerNo) + 1;
            //     }
            // }
            const payload = {
                current:payloadGen,
                improvements:payloadGen,
                userId
            }
            PostAPIService('calculation?getPage26Response', payload)
                .then(response => {
                    calculateEnergyFields(response.data);
                })
                .catch(error => console.log(error))
        }
    }

    function calculateEnergyFields(data: any) {
        const { energyUseElec, energyUseGas } = data.current;
        const energieproductie = getParameter("Zelfconsumptie zonnepanelen") || { value: 0 };
        const zonnepaneelAantallToSurfaceParam = getParameter("Zonnepanelen") || { value: 0 };
        const unitRateElec = getParameter("Kostprijs elektriciteit") || { value: 0 };
        const unitRateInjectie = getParameter("Injectietarief") || { value: 0 };
        const houseSolarValue = getLocalStateValue("23")?.aantal_zonnepanelen;

        let energyDifferencePanel = 0, energyRatePanel, verkoopElecPanel;

        if(houseSolarValue > 0) {
            const verbruikZonnepaneelKwh = getParameter("Opbrengst per zonnepaneel") || { value: 0 };
            const zelfconsumptieZonnepanelen = getParameter("Zelfconsumptie zonnepanelen") || { value: 0 };
            const area = parseInt(houseSolarValue) * zonnepaneelAantallToSurfaceParam?.value;
            const digitaleAnswer = getLocalStateValue("25").answerNo;
            if(digitaleAnswer === "0") {
                energyDifferencePanel = (area * verbruikZonnepaneelKwh.value * zelfconsumptieZonnepanelen.value) / 100;
                energyRatePanel = (area * verbruikZonnepaneelKwh.value * zelfconsumptieZonnepanelen.value * unitRateElec?.value + area * verbruikZonnepaneelKwh.value * (100 - zelfconsumptieZonnepanelen.value) * unitRateInjectie?.value) / 100
                verkoopElecPanel = area * verbruikZonnepaneelKwh.value * ((100 - zelfconsumptieZonnepanelen.value) / 100)
            }
            else {
                energyDifferencePanel = area * verbruikZonnepaneelKwh.value
                energyRatePanel = area * Number(verbruikZonnepaneelKwh.value) * Number(unitRateElec.value)
                verkoopElecPanel = area * verbruikZonnepaneelKwh.value * (100 - zelfconsumptieZonnepanelen.value)
            }
        }
        const getSolarPanelValue = getLocalStateValue("22");
        setHeatValue(Math.round(energyUseGas).toString());
        setElectrictyValue(Math.round(energyUseElec).toString());
        setSolarValue(getSolarPanelValue?.answerNo === "0" ? Math.round(energyDifferencePanel).toString() : "0");

        // check to see if the user already changed the values
        const localEnergyValues = {
            "elektriciteitsverbruik": Math.round(energyUseElec),
            "aardgasverbruik": Math.round(energyUseGas),
            "zonnepanelen": Math.round(energyDifferencePanel),
        }
        localStorage.setItem("energyValues", JSON.stringify(localEnergyValues));
        storeAnswer(localEnergyValues, "26");
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "w-full mt-[18px]" />
                <SubmitBox onSubmit = {onClick} isButtonActive = {enableButton()}>
                    <InputArea description = "Verbruik voor verwarming per jaar in kWh" marginLeft = {window.screen.width < 1000 ? "0px" : "138px"} value = {heatValue} setValue = {setHeatValue} enablePlaceholder = {false} />
                    <InputArea description = "Elektriciteitsverbruik per jaar in kWh" marginLeft = {window.screen.width < 1000 ? "0px" : "138px"} value = {electrictyValue} setValue = {setElectrictyValue} enablePlaceholder = {false} />
                    <InputArea description = "Opbrengst zonnepanelen per jaar in kWh" marginLeft = {window.screen.width < 1000 ? "0px" : "138px"} value = {solarValue} setValue = {setSolarValue} enablePlaceholder = {false} />
                </SubmitBox>
                <div className = "w-full mt-[100px]" />
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default HouseEnergy;