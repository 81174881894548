
import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import{setHeatLossData,getCalculationPayload} from "../../utils/resultActions"
import Layout from "../../components/layout";
import Card from "../../components/result/card";
import PriceCalculation from "../../components/result/priceCalculation";
import Bar from "../../components/result/bar";
import Points from "../../components/result/point";
import axios from 'axios';
import cardJson from "../../utils/cardsData.json"
import SimulationModal from "./simulationModal";
import {setAppText} from "../../utils/common";
import CreatePasswordComponent from "../../components/auth/createPassword/createPasswordComponent";
import { getLocalStateValue } from "../../utils/helpers";
import Cookies from "js-cookie";
type resultProps = {
    globals: {},
    cookies?: {
        userId?: string
    }
}
const Results = ({ globals, cookies }: resultProps) => {
    const [renderUpdate, setRenderUpdate] = useState(false)
    const [hasImprovement, setHasImprovement] = useState(false)

    const [initBar, setUnitBar] = useState(false)
    const [cardData, setCardData] = useState([]);
    const [displaySetPassword, setDisplaySetPassword] = useState(false);
    const [resultPageData, setResultPageData] = useState({
        result:{}
    });
    const location = useLocation();

    useEffect(() => {
        if(location?.search.includes('token') ) {
            setDisplaySetPassword(true)
        }
    }, [])


    const [state, setState] = useState({
        buildingData:[],
        isAreaUpdated: {
        Zonnepanelen: false,
        Zonnewering: false,
        Groendak: false,
        Hoofddak: false,
        },
    })
    const [colorDot, setColorDot] = useState([])
    const [load, setLoad] = useState(false)
    const [parameters, setParameters] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [heatLossDataUpdate, setHeatLossDataUpdate] = useState(false);
    const navigate = useNavigate()

    useEffect(()=>{

        (async () => {
            const localState : string = localStorage.getItem('localState') || ""
            localStorage.setItem("newState",localState)
    
            if(!localState || localState ==""){
                navigate("/");
                navigate(0)
            }
        })();


        

    },[])

    useEffect(()=>{
        getEnergy()
        getCall()
        setAppText(setResultPageData);
        getImprovements();
        getColorDots();
        getParameters();
        setUnitBar(true);

        

    },[])

    useEffect(()=>{

    },[resultPageData])


    const getImprovements = () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/improvements`)
            .then((response) => {
                setCardData(response.data);
            }).catch((error) => {
                console.log(error)
            });
    };
    const getParameters = () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/parameters`)
            .then((response) => {
                setParameters(response?.data?.data[0]);
                localStorage.setItem('parameters',  JSON.stringify(response?.data?.data[0]))
                const maximumbedragEnergielening = response?.data?.data[0].find((param : any) => param?.name === 'Maximumbedrag energielening') ?.value || 0
                localStorage.setItem('maximumbedragEnergielening', maximumbedragEnergielening)

            }).catch((error) => {
                console.log(error)
            });
    };

    const getColorDots = () => {

        let improvementcolor : string = JSON.parse(`${localStorage.getItem('improvementcolor')}`) || "";

        if(improvementcolor.length <=0){
            fetch(`${process.env.REACT_APP_BACKEND_URL}/improvementcolor`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                })
                .then((response) => response.json())
                .then((data) => {
                    localStorage.setItem('improvementcolor',JSON.stringify(data))
                    setColorDot(data)})
        }else{
                    // @ts-ignore
            setColorDot(improvementcolor)
        }

    
    }
    const localAnswers:any =  localStorage.getItem('localState')
    const localState = JSON.parse(localAnswers)
    const localImprovements = JSON.parse(localAnswers)
    const getEnergy = async() => {
        const payload = await getCalculationPayload(localState)
        const improvedPayload = await getCalculationPayload(
          localImprovements,
          true,
          0,
          true,
        )
        axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/calculation?getOverviewValues`, {
          current: payload,
          improvements: improvedPayload,
          userId:Cookies.get("userId"),
        })
        //@ts-ignore
        .then(async (response) => {
          const { newMeasures } = response.data
  
          localStorage.setItem(
            'energyGasNew',
            newMeasures.adjustedScaledGas + newMeasures.adjustedScaledElec,
    )
    setLoad(true)

})
    }

    const getCall = () => {
        let dimensions : string = JSON.parse(`${localStorage.getItem('standardDimensions')}`) || "";
        
        if(dimensions.length <= 0){
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/dimensions`).then((response) => {
                // setHeatLossData( response.data, false, heatLossDataUpdate, setHeatLossDataUpdate)
                localStorage.setItem('standardDimensions',JSON.stringify(response.data))
                setState((st) => ({
                    ...st,
                    buildingData: response.data,
                }))
            })
        }else{
        // @ts-ignore
        setState((st) => ({
                ...st,
                buildingData: dimensions,
            }))
        }
   
    }
    
    useEffect(() => {  
        if ( localImprovements && state?.buildingData?.length > 0) {
            // console.log('in useeffect conditon' )
            setHeatLossData(state.buildingData, false, heatLossDataUpdate, setHeatLossDataUpdate)
        }
      }, [state?.buildingData])

      const parseTitle = (title: string) => {
        if(title === "Achter- en zijgevels") {
            return "achtergevel"
        }
        else if(title === "Warm water") {
            return "warm_water"
        }
        else if(title === "Ramen leefruimte") {
            return "ramen_leefruimte"
        }
        else if(title === "Ramen slaapkamers") {
            return "ramen_slaapkamers"
        }
        else if(title === "Vocht?") {
            return "vocht"
        }
        else {
            return title.toLowerCase();
        }
    }

    const generateImprovementText = (answer: string, area: number) => {
        return `${answer} || ${area}`
    }

    const storeImprovementData = (area?: number, title?: string, answer?: string) => {
        if(cookies?.userId) {
            const payload = {
                sessionId: cookies?.userId,
                improvement_text: parseTitle(title || ""),
                selected_improvement: generateImprovementText(answer || "", area || 0)
            }
            axios.post(process.env.REACT_APP_BACKEND_URL + 'session/improvement', payload).then(response => {
                // console.log(response)
            }).catch(error => console.log(error))
        }
    }

    const removeImprovementData = (title: string) => {
        if(cookies?.userId) {
            const payload = {
                sessionId: cookies?.userId,
                improvement_text: parseTitle(title || ""),
            }
            axios.post(process.env.REACT_APP_BACKEND_URL + 'session/remove-improvement', payload).then(response => {
                // console.log(response)
            }).catch(error => console.log(error))
        }
    }

    const openSimulationModal = () => {
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        if(auth?.uid) {
            console.log(cardJson);
            // user is logged in 
            setIsModalOpen(true);
        } else {
            // user is not logged in
            setOpenLoginModal(true)
        }
    }

    const renderBijdak = (item: any) => {
        if(item?.title === "Bijdak") {
            const localState = getLocalStateValue("13");
            if(localState?.bijdak === "Niet van toepassing") {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }
  
    return (
        <Layout openloginModal={openLoginModal} setOpenLoginModal = {setOpenLoginModal}>
            <div className="flex flex-col justify-center">
                <div className="w-full flex flex-col md:flex-row justify-center sticky top-0 left-0 z-[9]">
                    {load && initBar && <Bar resultLabelData={resultPageData?.result} renderUpdate={renderUpdate} initBar={initBar} />}
                </div>
                {!load?(
                    <span>Laden....</span>
                ):(
                <div className="w-full md:flex-row flow-col justify-center box-border md:px-14">
                    <div className=" w-full max-w-[100%] overflow-hidden">
                        <Points />
                    </div>
                    <div className="flex w-full flex-col md:flex-row mt-4">
                     <div className="flex flex-[6] flex-col w-[1435.68] max-w-[100%]">
                            {cardJson?.map((item:any) =>
                            localImprovements?.[item.pageNumber] ?
                                <div className="flex flex-row w-full max-w-[100%] mb-2.5 overflow-hidden">
                                    { renderBijdak(item) && <Card
                                        carddata={item}
                                        APIdata={cardData}
                                        buildingData={state?.buildingData?.length > 0 ? state?.buildingData : []}
                                        resultSetState={setState}
                                        parameters={parameters}
                                        storeImprovementData={storeImprovementData}
                                        removeImprovementData={removeImprovementData}
                                        setRenderUpdate = {setRenderUpdate}
                                        renderUpdate = {renderUpdate}
                                        setHasImprovement={setHasImprovement}
                                    />}
                                </div>
                                :<></>
                            )}
                        </div>
                        <div className="flex flex-[4] w-[637px] max-w-[100%]">
                     <PriceCalculation pageData={resultPageData?.result} openSimulationmodal = {openSimulationModal} hasImprovement={hasImprovement}  setHasImprovement={setHasImprovement} />
                        </div>
                    </div>

                </div>)}
            </div>

            {isModalOpen ? (
                <SimulationModal cookies = {cookies} isModalOpen = {isModalOpen} setIsModalOpen = {setIsModalOpen} resultLabelData={resultPageData?.result} renderUpdate={renderUpdate}/>
            ): (
                <></>
            )}
            { displaySetPassword  ? <><CreatePasswordComponent/></> : <></> } 

        </Layout>

    )
};
export default Results;
