import axios from 'axios';
export const SET_IS_LAODING = 'SET_IS_LAODING'
export const SET_TEXT = 'SET_TEXT'


export function setIsLoading(payload:any) {
    return {
      type: SET_IS_LAODING,
      payload,
    }
  }
  function getObject(arr = []) {
    return arr.reduce((prevVal:any, currentVal:any) => {
      return { ...prevVal, [currentVal.key]: currentVal.value }
    }, {})
  }
  
  export function appText(payload:any) {
    return {
      type: SET_TEXT,
      payload,
    }
  }
//   function separateByPattern(obj:any, filter:any) {
//     let key
//     const keys = {}
//     for (key in obj)
//       if (obj.hasOwnProperty(key) && filter.test(key)) keys[key] = obj[key]
//     return keys
//   }
function separateByPattern(obj: any, filter: RegExp) {
    let key;
    const keys: { [key: string]: any } = {};
    for (key in obj) {
      if (obj.hasOwnProperty(key) && filter.test(key)) {
        keys[key] = obj[key];
      }
    }
    return keys;
  }
  export function parserResult(obj:any) {
    return {
      // singleCard: {
      //   title: obj.single_card_title,
      //   description: obj.single_card_description,
      //   leftButtonText: obj.single_card_left_button_text,
      //   rightButtonText: obj.single_card_right_button_text,
      // },
      // emailCard: {
      //   title: obj.email_card_title,
      //   emailLabel: obj.email_card_email_label,
      //   checkBoxText: obj.email_card_checkbox_text,
      //   privacyText: obj.email_card_privacy_text,
      //   buttonText: obj.email_card_button_text,
      //   success_text: obj.email_success_text,
      //   error_text: obj.email_error_text,
      // },
      // adviceCard: {
      //   title: obj.advice_card_title,
      //   introduction: obj.advice_card_introduction,
      //   buttonText: obj.advice_card_button_text,
      //   buttonLink: obj.advice_card_button_link,
      //   linkText: obj.advice_card_link_text,
      //   linkLink: obj.advice_card_link_link,
      // },
      // improvementCard: separateByPattern(obj, /improvement/),
      // savingCard: separateByPattern(obj, /saving/),
      // improvementModal: separateByPattern(obj, /improvement_modal/),
      epcCard: separateByPattern(obj, /epc/),
      // restartCard: separateByPattern(obj, /restart/),
      // disclaimerCard: separateByPattern(obj, /disclaimer/),
    }
  }
export function setAppText(setResultPageData:any) {
    
      // dispatch(setIsLoading(true))
      axios
        .all([
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/1`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/2`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/3`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/4`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/5`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/6`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/7`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/8`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/10`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/11`),
        ])
        .then((response) => {
          const dataArr = response.map((data) =>
            getObject(data.data[0].pageContent),
          )
            console.log('dataArr',dataArr);

          let result = parserResult(dataArr[1]);
          setResultPageData({result:result})
          // dispatch(
          //   appText({
          //   //   home: parseHomeJson(dataArr[0]),
          //     result: parserResult(dataArr[1]),
          //   //   questionnaire: parseQuestionnaire({ ...dataArr[2], ...dataArr[3] }),
          //   //   advice: parseAdvice(dataArr[4]),
          //   //   not_found: parsePageNotFound(dataArr[5]),
          //   //   modal_meer_info: parseMeerInfoModal(dataArr[6]),
          //   //   footer: dataArr[7],
          //   //   pdf: dataArr[8],
          //   //   modal: dataArr[9],
          //   }),
          // )
          // dispatch(setIsLoading(false))
        })
  
  }

