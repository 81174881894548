import React, { useRef, useEffect, useState, useMemo } from 'react'

import cardJson from "../../utils/cardsData.json"
import Card from "./card";
import Overview from "./overview";
import Bar from "./epcBar";
import Logo from "../../assets/logo/g10.svg"
import './pdf-dom.css'
// import './result.scss'
import { useCookies } from 'react-cookie'
type pdfProps = {
  resultLabelData: any,
  renderUpdate:any
}
const PdfDom = ({resultLabelData,renderUpdate }: pdfProps) => {
  const [cookies, setCookies] = useCookies()
let cardMetaData:any[] = JSON.parse(localStorage.getItem('pdfData')!)
const localImprovements = JSON.parse(localStorage.getItem('localState')!)
const DUTCH_MONTHS = [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
      ]
      

  // EPC labels helper
  const date = new Date()
  return (
       //@ts-ignore
    <div id="pdf-dom" className="container pdf-container">
      <header className="header">
      
      </header>
      <main className="detail-layout content-container pdf-content-container">
        <div className = "w-full flex flex-col md:px-16 xl:px-32 box-border py-[80px]">
          {/* <div className = "flex w-full justify-between items-center">
            <img className = "h-[50px] object-contain" alt = "logo" src = {Logo}/>
            <p className = "font-Source font-semibold text-[19px] text-black">{`${date.getDate()} ${DUTCH_MONTHS[date.getMonth()]} ${date.getFullYear()}`}</p>
          </div> */}
          <p className = "font-Source font-semibold text-[50px] text-black mt-[20px]">Maak je huis klaar voor morgen</p>
          {/*<p className = "font-Source font-regular text-[24px] text-black">Dit is je persoonlijk plan met verbeteringen voor je huis. Wil je hier graag verder op ingaan? Boek een advies bij de Energiecentrale.</p>*/}
          <p className = "font-Source font-regular text-[24px] text-black">Dit is je persoonlijk plan met verbeteringen voor je huis. Wil je hier graag verder op ingaan? Vraag advies bij <a className="underline text-blue-500" href='https://mijnenergiehuis.be/' target="_blank"><strong>jouw lokaal energiehuis</strong>.</a>
          </p>
            <p className = "font-Source font-semibold text-[20px] text-black mt-[20px]">Voordelen energiezuinig renoveren</p>
            <p className = "font-Source font-regular text-[16px] text-black">Je woning energetisch renoveren heeft belangrijke voordelen:</p>
            <ul className="list-disc pl-[1%]">
                <li className = "font-Source font-regular text-[16px] text-black">Een <strong>lagere energiefactuur:</strong> in een goed geïsoleerde woning zijn de verwarmingskosten tot 70% lager dan in een niet-geïsoleerde woning. Wek je zelf groene energie op via zonnepanelen of een zonneboiler? Dan kan je verder besparen op je energiefactuur.</li>
                <li className = "font-Source font-regular text-[16px] text-black">De extra investering verdien je niet enkel terug via een lagere energiefactuur: er zijn ook <strong>verschillende premies en een lening</strong> waar je gebruik van kan maken.</li>
                <li className = "font-Source font-regular text-[16px] text-black">De <strong>waarde van je woning stijgt:</strong> kopers/huurders hebben aandacht voor het energieverbruik van de woning die ze kopen/huren, ze hebben dan ook een opvallend hogere waarde op de vastgoedmarkt.</li>
                <li className = "font-Source font-regular text-[16px] text-black">Een goed gerenoveerde woning vraagt niet alleen <strong>minder onderhoud</strong>, je vermijdt er ook extra onvoorziene problemen en kosten mee.</li>
                <li className = "font-Source font-regular text-[16px] text-black"><strong>Meer comfort en gezonder binnenklimaat:</strong> er is een betere geluidsdemping en een constante temperatuur in de woning, niet alleen in koude winters maar ook tijdens warme zomers.</li>
                <li className = "font-Source font-regular text-[16px] text-black"><strong>Kleinere impact op het klimaat:</strong> hoe minder energie verbruikt wordt en hoe groter het aandeel hernieuwbare energie, hoe lager de CO2-uitstoot. Zo stop jij mee de klimaatverandering.</li>
            </ul>
            <p className = "font-Source font-semibold text-[20px] text-black mt-[20px]">Stappenplan</p>
            <p className = "font-Source font-regular text-[16px] text-black">De volgorde van de werken hangt af van tal van factoren: de wijze waarop de woning ooit werd gebouwd en al werd gerenoveerd, je budget, de architectuur, het type en de huidige toestand van je woning. Een volgorde die vaak wordt gevolgd, is eerst het isoleren van de bouwschil (het dak, de buitenmuren, het schrijnwerk en de vloeren) en dan komen de zogenaamde ‘energiezuinige technieken’ aan bod voor verwarming en sanitair warm water, ventilatie, verlichting en elektriciteit. Je kan het energieprestatiepeil van je woning verder laten dalen als je ook zelf  hernieuwbare energie opwekt met bijvoorbeeld zonnepanelen of een zonneboiler. Daarnaast kan je ook denken aan goed waterbeheer, bijvoorbeeld via het recupereren van hemelwater of het leggen van een groendak.</p>
            <p className = "font-Source font-regular text-[16px] text-black"></p>
            <p className = "font-Source font-regular text-[16px] text-black">Maar ieder project heeft uiteraard zijn eigen aanpak, en vaak lopen er werken door elkaar heen. Denk in elk geval vooraf goed na zodat je “lock-ins” achteraf voorkomt. Door een lock-in kan je toekomstige werken niet meer uitvoeren zonder eerst weer af te breken. Isoleer dus eerst je dak vooraleer je zonnepanelen plaatst en renoveer je buitenschrijnwerk en dakgoot zodat muurisolatie achteraf nog mogelijk is. Denk ook na over waar en hoe je alle technieken in je project wil inpassen, zodat bijvoorbeeld al vooraf de ventilatiekanalen, wachtleidingen en elektriciteit kunnen worden geplaatst.</p>
            <p className = "font-Source font-semibold text-[20px] text-black mt-[20px]">Financieel plan</p>
            <p className = "font-Source font-regular text-[16px] text-black">Je huis energetisch renoveren kost geld. Gelukkig zijn er heel wat leningen en premies beschikbaar. Vraag na bij <a className="underline text-blue-600" href='https://mijnenergiehuis.be/' target="_blank">jouw lokaal energiehuis</a>  waar jij recht op hebt. Op <a className="underline text-blue-600" href='https://www.premiezoeker.be/' target="_blank">https://www.premiezoeker.be/</a> vind je al heel wat premies terug.</p>
        </div>
        <hr />
        <div className="w-full">
          {cardJson.map((item:any, index:any) => {
            const valid = Object.keys(
              localImprovements?.[item.pageNumber] || [],
            ).length
            return valid ? (
              <div
                className="w-full mt-[38px]"
                style={{ breakInside: 'avoid' }}
              >
                <div
                  key={item.title}
                  id={item.title}
                  className={`w-full ${
                    cardJson.length - 1 === index && 'no-after'
                  }`}
                >
                  <div className="card-content w-full box-border md:px-16 xl:px-32">
                    <Card carddata = {cardMetaData?.[item.pageNumber]}/>
                  </div>
                </div>
              </div>
            ) : null
          })}
        </div>
        <div className = "my-[35px] w-full flex flex-col box-border md:px-16 lg:px-16 xl:px-32" style={{ breakInside: 'avoid' }}>
          <Overview />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ width: '100%', marginTop: '0' }}>
              <Bar resultLabelData={resultLabelData} renderUpdate={renderUpdate} setPdfDataUpdate = {false} pdfHtml ={""}/>
                {/* {epc} */}
              </div>
            </div>
        </div>
      </main>
    </div>
  )
}
export default PdfDom
