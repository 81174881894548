
import { parseRate, getCalculationPayload } from './resultActions'
import axios from 'axios';
import {pageTitles,  } from './constants'
export function getObjValuesSum(values:number) {
  // console.log(values,'values')
  if(values !== undefined && values !== null){
  return Math.round(
    Object.values(values)
      .reduce((val, current) => val + Number(current), 0)
      .toFixed(2),
  )
  }
  else{
    return 0
  }
}
export async function getOverviewValues(
  localState:any,
  localImprovements:any,
  setMinderEnergieValue:any,
  setCO2Value:any,
  setBespaarValue:any,
  setElectricityValue:any,
  setGasValue:any,
  userId:any,
) {

  if(localState && localImprovements){
    const obj:any = localStorage.getItem('parameters')
    const parameters = JSON.parse(obj)
    const params = parameters?.data?.data?.[0] || []
    const gasParam =
      params.find((param:any) => param?.name == 'CO2/kWh voor gas')?.value || 0
    const electParam =
      params.find((param:any) => param?.name == 'CO2/kWh voor elektriciteit')
        ?.value || 0
    const unitRateGas = parseRate(parameters, 0)
    const unitRateElec = parseRate(parameters, 1)
    const unitRateInjectie = parseRate(parameters, 2)
    const payload = await getCalculationPayload(localState)
    const improvedPayload = await getCalculationPayload(
      localImprovements,
      true,
      0,
      true,
    )

  
    let energyDifferencePanel:number = 0
    let energyRatePanel:number = 0
    let verkoopElecPanel = 0
    const verbruikZonnepaneelKwh =
      parameters?.data?.data[0].find((item:any) => item.id == '35') || 0
    const zelfconsumptieZonnepanelen =
      parameters?.data?.data[0].find((item:any) => item.id == '33') || 0
    const area = Number(localImprovements[23]?.aantal_zonnepanelen)
  
    if (
      localImprovements[23]?.aantal_zonnepanelen > 0 &&
      localState[23]?.aantal_zonnepanelen == ''
    ) {
      // console.log("getOverviewValues - calculation zonnepannel start")
  
      // await axios
      //   .get(`${process.env.REACT_APP_BACKEND_URL}/dimensions?getDimensionsZonnepannelen`)
      //   .then((response) => {
      //     const data = response.data.filter((d) => Number(d.id) == 1)
      //     const buildingData=response.data
  
      //     const opperKey = getOppervlakteKey(localImprovements, buildingData && buildingData[0])
  
      //     // const area = Math.round(data[0][opperKey]);
  
      if (localImprovements[25].answerNo == 0) {
        energyDifferencePanel =
          (Number(area) *Number(verbruikZonnepaneelKwh.value) *Number(zelfconsumptieZonnepanelen.value)) /100
        energyRatePanel =
          (area *verbruikZonnepaneelKwh.value *zelfconsumptieZonnepanelen.value *unitRateElec +area *verbruikZonnepaneelKwh.value *
            (100 - zelfconsumptieZonnepanelen.value) *
            unitRateInjectie) /100
        verkoopElecPanel =
          area *verbruikZonnepaneelKwh.value *((100 - zelfconsumptieZonnepanelen.value) / 100)
      }
      if (localImprovements[25].answerNo == 1) {
        energyDifferencePanel = area * parseInt(verbruikZonnepaneelKwh.value)
        energyRatePanel =
          area * Number(verbruikZonnepaneelKwh.value) * Number(unitRateElec)
        verkoopElecPanel =
          area *
          Number(verbruikZonnepaneelKwh.value) *
          (100 - Number(zelfconsumptieZonnepanelen.value))
      }
      // })
      // .catch((err) => {
      //   console.log("error getDimensionsZonnepannelen",err)
      //     return 0
      // })
    }
  
    // console.log("getOverviewValues energyDifferencePanel",energyDifferencePanel)
    // console.log('getOverviewValues energyRatePanel', energyRatePanel)
    // console.log("getOverviewValues verkoopElecPanel",verkoopElecPanel)
    // console.log("getOverviewValues area",area)
    // console.log("getOverviewValues verbruikZonnepaneelKwh",verbruikZonnepaneelKwh.value)
    // console.log("getOverviewValues energyDifferencePanel",zelfconsumptieZonnepanelen.value)
    // console.log("getOverviewValues energyRatePanel",energyRatePanel)
    // console.log("getOverviewValues energyRatePanel",verkoopElecPanel)
  
    const storedResult:any = localStorage.getItem('result')
    const result = JSON.parse(storedResult)
    const storedCurrentPayload = localStorage.getItem('currentPayload')
    const storeImprovedPayload = localStorage.getItem('improvedPayload')
    const checkCurrentPayload = JSON.stringify(payload) == storedCurrentPayload
  
    const checkImprovedPayload =
      JSON.stringify(improvedPayload) == storeImprovedPayload
  
    if (checkCurrentPayload && checkImprovedPayload && storedResult) {
      const { newMeasures } = result.data
  
      localStorage.setItem(
        'energyGasNew',
        newMeasures.adjustedScaledGas + newMeasures.adjustedScaledElec,
      )
      const gas:number = Number (result.data.estimatedSavings?.gas)
      const elec:number = Number (result.data.estimatedSavings?.elec)
      const co2 =
        Number(
          gas * gasParam + (elec + energyDifferencePanel) * electParam,
        ) > 0
          ? Number(
              gas * gasParam + (elec + energyDifferencePanel) * electParam,
            ).toFixed(2)
          : 0
      // const difference = Number(gas + elec).toFixed(2) > 0 ? Number(gas + elec).toFixed(2) : 0
      const difference = Number(gas + elec) > 0 ? Number(gas + elec).toFixed(2) : 0
//       const co2 =
//   parseFloat(
//     (
//       gas * parseInt(gasParam) +
//       (elec + Number(energyDifferencePanel)) * parseInt(electParam)
//     ).toFixed(2)
//   ) > 0
//     ? parseFloat(
//         gas * gasParam + (elec + energyDifferencePanel) * electParam,
//       ).toFixed(2)
//     : 0;
// const difference =
//   parseFloat(gas + elec).toFixed(2) > 0 ? parseFloat(gas + elec).toFixed(2)
      const energyRate = Number(
        Number(gas * unitRateGas) + Number(elec * unitRateElec),
      ).toFixed(2)
      setCO2Value(Number(co2).toFixed(2))
      setMinderEnergieValue(
        (Number(difference) + energyDifferencePanel).toFixed(2),
      )
      setBespaarValue((Number(energyRate) + energyRatePanel).toFixed(2))
      setElectricityValue((Number(elec) + energyDifferencePanel).toFixed(2))
      setGasValue(Number(gas).toFixed(2))
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/calculation?getOverviewValues`, {
          current: payload,
          improvements: improvedPayload,
          userId,
        })
        //@ts-ignore
        .then(async (response) => {
          const { newMeasures } = response.data
  
          localStorage.setItem(
            'energyGasNew',
            newMeasures.adjustedScaledGas + newMeasures.adjustedScaledElec,
          )
          const gas = response.data.estimatedSavings?.gas
          const elec = response.data.estimatedSavings?.elec
          const co2 =Number(gas * gasParam + (elec + energyDifferencePanel) * electParam,) > 0
              ? Number(
                  gas * gasParam + (elec + energyDifferencePanel) * electParam,
                ).toFixed(2)
              : 0
          const difference =
            Number(gas + elec) > 0 ? Number(gas + elec).toFixed(2) : 0
          const energyRate = Number(
            Number(gas * unitRateGas) + Number(elec * unitRateElec),
          ).toFixed(2)
          setCO2Value(Number(co2).toFixed(2))
          setMinderEnergieValue(
            (Number(difference) + energyDifferencePanel).toFixed(2),
          )
          setBespaarValue((Number(energyRate) + energyRatePanel).toFixed(2))
          setElectricityValue((Number(elec) + energyDifferencePanel).toFixed(2))
          setGasValue(Number(gas).toFixed(2))

          // window.location.reload();
        })
        //@ts-ignore
        .catch((exception) => console.log(exception))
    }
  }
 
}
export function getOptionFun(values:any, dropDownOptions:any, answerKey:any) {
  // console.log(values,'values',dropDownOptions,"dropDownOptions",answerKey,"answerKey")
  if (dropDownOptions) {
    if (Object.keys(dropDownOptions[0]).length > 1) {
      const optVal = Number(values[answerKey])
      const rVal = Object.keys(dropDownOptions[0]).filter(
        (dp) => Number(dropDownOptions[0][dp]) == optVal,
      )
      return rVal[0]
    }
    return Object.keys(dropDownOptions[0])[0]
  }
  return ''
}
export const getHiddenTitle = (pageNo:any, selectionNo:any = 0) => {
  //@ts-ignore
  const title = pageTitles?.[`questionnaire${pageNo}`]
  if (title) {
    return title.replace(' | Gent: Check je huis', '')
  }

  if (pageNo == 5) {
    switch (selectionNo) {
      case 0:
        return 'Gas'
      case 1:
        return 'Elektriciteit'
      case 2:
        return 'Stookolie'
      case 3:
        return 'Een andere bron'
      default:
        return ''
    }
  }
}

export const getImprovedPages = (
  values:any,
  answerKey:any,
  selectedText:any,
  componentProps:{
    localImprovements:any,
    page6Answer:number
    pageNo:number,
  }
) => {
  let { localImprovements, page6Answer,pageNo } = componentProps
  const data = localImprovements
 
  // console.log("componentProps",componentProps)

  const answerNo = values[answerKey]
  console.log('pages', values);
  // console.log("pageNo",pageNo)
  // console.log("answerNo",values)
  // console.log("answerNo",selectedText)
if(pageNo == 6){
  pageNo = 7
}
  return {
    ...data,
    [pageNo]: {...data?.[pageNo],
      answerNo,
     //@ts-ignore 
     [getQuestionKey(Number(pageNo), Number(page6Answer))]: selectedText,
  
  }
}
}

export const getQuestionKey = (pageNo:number, page6AnswerNo:number) => {
 
  if (Number(pageNo) == 7) {
    switch (page6AnswerNo) {
      case 0:
        return 'gas'
      case 1:
        return 'elektriciteit'
      case 2:
        return 'stookolie'
      case 3:
        return 'ean_andere_bron'
      default:
        return 'gas'
    }
  }
 
  return getHiddenTitle(pageNo) && getHiddenTitle(pageNo)?.toLowerCase()
  ? getHiddenTitle(pageNo)?.toLowerCase()?.split(' ')?.join('_')
  : null
}

export function pageStatus(pageNo:number, value : number |  null = null,value2 : number |  null = null){
let localState = JSON.parse (localStorage.getItem('localState')!);
//the below line is where I made changes
// let localImprovements = JSON.parse(localStorage.getItem('localImprovements')!)
let localImprovements = undefined
let answers = localImprovements?.[pageNo] !== undefined && localImprovements?.[pageNo] !==null ? localImprovements :localState
let page7AnswerNo = (Number(answers[7]?.answerNo)).toString();
// console.log("pageStatus",pageNo,value, page7AnswerNo)
if(pageNo == 6){
  if(value){answers[6].answerNo = value}

  if (
    (answers[6]?.answerNo == "0" && (page7AnswerNo == "0" || page7AnswerNo == "1")) ||
    (answers[6]?.answerNo == "1" && page7AnswerNo == "4") || 
    (answers[6]?.answerNo == "2" && (page7AnswerNo == "10" || page7AnswerNo == "11")) ||
    (answers[6]?.answerNo == "3" && page7AnswerNo == "16")
  ) {
    return 'red'
  }
  if (
    (answers[6]?.answerNo == "0" && (page7AnswerNo == "2" || page7AnswerNo == "3")) ||
    (answers[6]?.answerNo == "1" && page7AnswerNo == "5") || 
    (answers[6]?.answerNo == "2" && (page7AnswerNo == "12" || page7AnswerNo == "13")) ||
    (answers[6]?.answerNo == "3" && page7AnswerNo == "15")
  ) {
    return 'orange'
  }
  return 'green'
}
if(pageNo == 11){
  if(value){answers[11].answerNo = value}

  if (answers[11]?.answerNo == "3") {
      return 'red'
    }
    if (answers[11]?.answerNo == 4) {       
      return 'orange'
    }
    if (answers[11]?.answerNo == "0" ) {
      if (
        (answers[6]?.answerNo == "0" &&
          (page7AnswerNo == "0" || page7AnswerNo == "1")) ||
        (answers[6]?.answerNo == "1" && page7AnswerNo == "0") ||
        (answers[6]?.answerNo == "2" &&
          (page7AnswerNo == "0" || page7AnswerNo == "1")) ||
        (answers[6]?.answerNo == "3" && page7AnswerNo == "2")
      ) {       
        return 'red'
      }
      if (
        (answers[6]?.answerNo == "0" &&
          (page7AnswerNo == "2" || page7AnswerNo == "3")) ||
        (answers[6]?.answerNo == "1" && page7AnswerNo == "1") ||
        (answers[6]?.answerNo == "2" &&
          (page7AnswerNo == "2" || page7AnswerNo == "3")) ||
        (answers[6]?.answerNo == "3" && page7AnswerNo == "1")
      ) {
        return 'orange'
      }
    }
    return 'green'
}
if(pageNo == 12){
  if(value){answers[12].answerNo = value}

    if(answers[12]?.answerNo == "0" || answers[12]?.answerNo == "1"){
        return 'red'
    }
    if (answers[12]?.answerNo == "2") {
        return 'orange'
    }
    if (answers[12]?.answerNo == "2") {
        return 'green'
    }
    return 'green'
}
if(pageNo == 13){
  if(value){answers[13].answerNo = value}

if (
    answers[13]?.answerNo == "0" ||
    answers[13]?.answerNo == "1"
  ) {

    return 'red'
  }
  if (
    // (answers[14]?.answerNo == "1" || answers[14]?.answerNo == "2") &&
    answers[13]?.answerNo == "2"
  ) {


    return 'orange'
  }
  if (answers[13]?.answerNo == "2") {

    return 'green'
  }


  return 'green'
}
if(pageNo == 15){
  if(value){answers[15].answerNo = value}

    if (
        ((answers[9]?.answerNo == "0" || answers[9]?.answerNo == "1") &&
          (answers[15]?.answerNo == "0" || answers[15]?.answerNo == "1")) ||
        ((answers[9]?.answerNo == "2" || answers[9]?.answerNo == 3) &&
          answers[15]?.answerNo == "0")
      ) {
    
        return 'red'
      }
      if (
        (answers[9]?.answerNo == "2" || answers[9]?.answerNo == 3) &&
        answers[15]?.answerNo == "1"
      ) {
    
        return 'orange'
      }
      if (
        (answers[9]?.answerNo == "0" ||
          answers[9]?.answerNo == "1" ||
          answers[9]?.answerNo == "2" ||
          answers[9]?.answerNo == 3) &&
        answers[15]?.answerNo == "2"
      ) {
      
        return 'green'
      }
    
    
      return 'green'
}

if(pageNo== 16){
  if(value){answers[16].answerNo = value}

    if (
        ((answers[10]?.answerNo == "0" || answers[10]?.answerNo == "1") &&
          (answers[16]?.answerNo == "0" || answers[16]?.answerNo == "1")) ||
        ((answers[10]?.answerNo == "2" || answers[10]?.answerNo == 3) &&
          answers[16]?.answerNo == "0")
      ) {
      
    
        return 'red'
      }
      if (
        (answers[10]?.answerNo == "2" || answers[10]?.answerNo == 3) &&
        answers[16]?.answerNo == "1"
      ) {
      
    
        return 'orange'
      }
      if (
        (answers[10]?.answerNo == "0" ||
          answers[10]?.answerNo == "1" ||
          answers[10]?.answerNo == "2" ||
          answers[10]?.answerNo == 3) &&
        answers[16]?.answerNo == "2"
      ) {
        
    
        return 'green'
      }
      
      return 'green'
}
if(pageNo == 17){
  if(value){answers[17].answerNo = value}
  if(value2){answers[9].answerNo = value2}
  // console.log("===>",answers[9]?.answerNo)
  // console.log("===>",answers[17].answerNo)
    if (
        (answers[9]?.answerNo == "0" || answers[9]?.answerNo == "1") &&
        answers[17]?.answerNo == "1"
      ) {
      
    
        return 'red'
      }
      if (
        (answers[9]?.answerNo == "0" && answers[17]?.answerNo == "2") ||
        ((answers[9]?.answerNo == "2" || answers[9]?.answerNo == 3) &&
          answers[17]?.answerNo == "1")
      ) {
        
    
        return 'orange'
      }
      if (
        (answers[9]?.answerNo == "1" ||
          answers[9]?.answerNo == "2" ||
          answers[9]?.answerNo == 3) &&
        answers[17]?.answerNo == "2"
      ) {
      
    
        return 'midgreen'
      }
    
      return 'green'
}
  if(pageNo == 18){
    if(value){answers[18].answerNo = value}

    const colors = ['red', 'orange', 'green', 'green'];
    const getPriority = (page:number) => {
      if (
          (answers[9]?.answerNo == "0" || answers[9]?.answerNo == "1") &&
          answers[page]?.answerNo == "1"
      ) {
        return 0
      }
      if (
          (answers[9]?.answerNo == "2" || answers[9]?.answerNo == 3) &&
          (answers[page]?.answerNo == "1" || answers[pageNo]?.answerNo == "2")
      ) {
        return 1
      }
      if (
          (answers[9]?.answerNo == "0" || answers[9]?.answerNo == "1") &&
          answers[page]?.answerNo == "2"
      ) {
        return 2
      }

      return 3
    }
    const page18Priority = getPriority(18)
    const page19Priority = getPriority(19)
    // console.log(page18Priority,'page18Priority');
    // console.log(page19Priority,'page19Priority');
    // if (page18Priority < page19Priority) {
    //
    //   const status = colors[page18Priority].toString();
    //   return `${status}`
    // }
    //
    // if (page19Priority < page18Priority) {
    //
    //   const status = colors[page19Priority].toString()
    //   return `${status}`
    // }


    const status = colors[page18Priority].toString()
    return `${status}`

  }
if(pageNo == 19){
  if(value){answers[19].answerNo = value}

    const colors = ['red', 'orange', 'green', 'green'];
    const getPriority = (page:number) => {
        if (
          (answers[9]?.answerNo == "0" || answers[9]?.answerNo == "1") &&
          answers[page]?.answerNo == "1"
        ) {
          return 0
        }
        if (
          (answers[9]?.answerNo == "2" || answers[9]?.answerNo == 3) &&
          (answers[page]?.answerNo == "1" || answers[pageNo]?.answerNo == "2")
        ) {
          return 1
        }
        if (
          (answers[9]?.answerNo == "0" || answers[9]?.answerNo == "1") &&
          answers[page]?.answerNo == "2"
        ) {
          return 2
        }
    
        return 3
      }
      const page18Priority = getPriority(18)
      const page19Priority = getPriority(19)
      // console.log(page18Priority,'page18Priority');
      // console.log(page19Priority,'page19Priority');
      if (page18Priority < page19Priority) {
      
        const status = colors[page18Priority].toString();
        return `${status}`
      }
    
      if (page19Priority < page18Priority) {
      
        const status = colors[page19Priority].toString()
        return `${status}`
      }
    
    
        const status = colors[page18Priority].toString()
        return `${status}` 
      
}
if(pageNo == 20){
  if(value){answers[20].answerNo = value}

    if (answers[20]?.answerNo == "1" || answers[20]?.answerNo == "2") {
        return 'orange'
      }
      return 'green'
}
if(pageNo == 21){
  if(value){answers[21].answerNo = value}

    if (answers[21]?.answerNo == "0") {
        return 'red'
      }
      if (answers[21]?.answerNo == "1" || answers[21]?.answerNo == "2") {
        return 'orange'
      }
      if (answers[21]?.answerNo == "3") {
        return 'green'
      }        
      return 'green'
}
if(pageNo == 22){
  if(value){answers[22].answerNo = value}

    if (answers[22]?.answerNo == "1") {
        return 'red'
      }
      return 'green'
}
if(pageNo == 27){
  if(value){answers[27].answerNo = value}

    if (answers[27]?.answerNo == "1") {
        return 'red'
      }
      return 'green'
}
if(pageNo == 29){
  if(value){answers[29].answerNo = value}

    if (answers[29]?.answerNo == "1") {
        return 'red'
      }
      return 'green'
}
if(pageNo == 31){
  if(value){answers[31].answerNo = value}

    if (answers[31]?.answerNo == "0") {
        return 'red'
      }
      return 'green'
}
 
if(pageNo == 33){
  if(value){answers[33].answerNo = value}

  if (answers[33]?.answerNo == "0") {
      return 'red'
    }
    if (answers[33]?.answerNo == "1") {
      return 'green'
    }
    if (answers[33]?.answerNo == "2") {
      return 'orange'
    }
    return 'green'
}
if(pageNo == 34){
  if(value){answers[34].answerNo = value}

  if (answers[34]?.answerNo == "0") {
      return 'red'
    }
    if (answers[34]?.answerNo == "1") {
      return 'green'
    }
    if (answers[34]?.answerNo == "2") {
      return 'orange'
    }
    return 'green'
}
}

export function getVerwarmingTableData(data:any, modalTitle:any, selectedOptText:any) {
  // debugger
let tableData:any = []
if (
  modalTitle == 'Verwarming: gas' ||
  modalTitle == 'Verwarming: elektriciteit'
) {
  data.map((table:any) => {
    if (
      table.improvementName == 'Verwarming: Gas' ||
      table.improvementName == 'Verwarming: Elektriciteit' ||
      table.improvementName == 'Verwarming: Anderebron'
    ) {
      tableData.push(table)
    }
  })
}
if (modalTitle == 'Verwarming: stookolie') {
  data.map((table:any) => {
    if (
      table.improvementName == 'Verwarming: Elektriciteit' ||
      table.improvementName == 'Verwarming: Stookolie'
    ) {
      tableData.push(table)
    }
  })
}
if (modalTitle == 'Verwarming: eenanderebron') {
  data.map((table:any) => {
    if (
      table.improvementName == 'Verwarming: Elektriciteit' ||
      table.improvementName == 'Verwarming: Anderebron'
    ) {
      tableData.push(table)
    }
  })
}
if (tableData.length > 0) {
  tableData = tableData
    .filter((table:any) =>
      table.improvementOptions.some(
        (imp:any) => imp.selectedOption == selectedOptText,
      ),
    )
    .map((table:any) => {
      return {
        ...table,
        improvementOptions: table.improvementOptions.filter(
          (imp:any) => imp.selectedOption == selectedOptText,
        ),
      }
    })
}


return tableData
}

export function getOppervlakteKey(pages:any, buildingData:any = {}) {

if (
  (pages?.[1]?.answerNo == 0 &&
    pages?.[3]?.answerNo == 0 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 0 &&
    pages?.[3]?.oppervlakte_input >= buildingData.groot_open)
) {
  return 'groot_open'
}
if (
  (pages?.[1]?.answerNo == 0 &&
    pages?.[3]?.answerNo == 1 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 0 &&
    pages?.[3]?.oppervlakte_input < buildingData.groot_open &&
    pages?.[3]?.oppervlakte_input > buildingData.klein_open)
) {
  return 'middel_open'
}
if (
  (pages?.[1]?.answerNo == 0 &&
    pages?.[3]?.answerNo == 2 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 0 &&
    pages?.[3]?.oppervlakte_input <= buildingData.klein_open)
) {
  return 'klein_open'
}
if (
  (pages?.[1]?.answerNo == 1 &&
    pages?.[3]?.answerNo == 0 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 1 &&
    pages?.[3]?.oppervlakte_input >= buildingData.groot_halfopen)
) {
  return 'groot_halfopen'
}
if (
  (pages?.[1]?.answerNo == 1 &&
    pages?.[3]?.answerNo == 1 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 1 &&
    pages?.[3]?.oppervlakte_input < buildingData.groot_halfopen &&
    pages?.[3]?.oppervlakte_input > buildingData.klein_halfopen)
) {
  return 'middel_halfopen'
}
if (
  (pages?.[1]?.answerNo == 1 &&
    pages?.[3]?.answerNo == 2 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 1 &&
    pages?.[3]?.oppervlakte_input <= buildingData.klein_halfopen)
) {
  return 'klein_halfopen'
}
if (
  (pages?.[1]?.answerNo == 2 &&
    pages?.[3]?.answerNo == 0 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 2 &&
    pages?.[3]?.oppervlakte_input >= buildingData.groot_gesloten)
) {
  return 'groot_gesloten'
}
if (
  (pages?.[1]?.answerNo == 2 &&
    pages?.[3]?.answerNo == 1 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 2 &&
    pages?.[3]?.oppervlakte_input < buildingData.groot_gesloten &&
    pages?.[3]?.oppervlakte_input > buildingData.klein_gesloten)
) {
  return 'middel_gesloten'
}
if (
  (pages?.[1]?.answerNo == 2 &&
    pages?.[3]?.answerNo == 2 &&
    !pages?.[3]?.oppervlakte_input) ||
  (pages?.[1]?.answerNo == 2 &&
    pages?.[3]?.oppervlakte_input <= buildingData.klein_gesloten)
) {
  return 'klein_gesloten'
}
return ''
}

export function getOppervlakteId(modalTitle:any,pageIndex:any) {
//regenput
if(pageIndex == 29){
  return { key1: 2, key2: 3 }
}
if (modalTitle == 'Vloerisolatie') {
  return 2
}
if (modalTitle == 'Voorgevel') {
  return 4
}
if (modalTitle == 'Achter- en zijgevels') {
  return 5
}
if (modalTitle == 'Ramen leefruimte') {
  return 7
}
if (modalTitle == 'Ramen slaapkamers') {
  return 8
}
if (modalTitle == 'Zonnewering') {
  return { key1: 7, key2: 8 }
}
if (modalTitle == 'Groendak') {
  return { key1: 9, key2: 10 }
}
if (modalTitle == 'Hoofddak') {
  return { key1: 9, key2: 10, key3: 9 }
}
if (modalTitle == 'Bijdak') {
  return 10
}
return 1
}
export function getPremImprovment(modalTitle:any, pageIndex:any, selectedOptText:any, pages:any) {
if (modalTitle == 'Verwarming') {
  if (pages[6]?.verwarming == 'Een andere bron') {
    return `${modalTitle} - Andere bron - ${selectedOptText}`
  }
  if (
    selectedOptText == 'Hybride warmtepomp' ||
    selectedOptText == 'Warmtepomp lucht/lucht' ||
    selectedOptText == 'Warmtepomp lucht/water' ||
    selectedOptText == 'Warmtepomp water/water' ||
    selectedOptText == 'Warmtepomp bodem/water'
  ) {
    return `${modalTitle} - Elektriciteit - ${selectedOptText}`
  }
  if (selectedOptText == 'Warmtenet') {
    return `${modalTitle} - Andere bron - ${selectedOptText}`
  }
  return `${modalTitle} - ${pages[6].verwarming} - ${selectedOptText}`
}
if (modalTitle == 'Zonnepanelen') {
  return `${modalTitle}`
}
if (modalTitle == 'Groendak') {
  return `${modalTitle}`
}
if (modalTitle == 'Regenput') {
  return `${modalTitle}`
}
if (pageIndex == 30) {
  return `${modalTitle}`
}
return `${modalTitle} - ${selectedOptText}`
}

export function getModalOptions(title:any, pages:any, pageIndex:any, parameters:any) {

// console.log("getModalOptions",title, pages, pageIndex, parameters)

if(pageIndex == 33){
  // console.log("getModalOptions asbest",pages?.[33]?.answerNo)
  if (pages?.[33]?.answerNo == 0) {
    return [
      { 'Asbest Verwijderen': 1, 'Asbest inventariseren': 2 },
    ]
  }
  if (pages?.[33]?.answerNo == 2) {
    return [
      { 'Asbest inventariseren': 2 },
    ]
  }
}
if(pageIndex == 34){
  // console.log("getModalOptions asbest",pages?.[33]?.answerNo)
  if (pages?.[34]?.answerNo == 0) {
    return [
      { 'Vochtbestrijden': 1, 'Vochtanalyse': 2 },
    ]
  }
  if (pages?.[34]?.answerNo == 2) {
    return [
      { 'Vochtanalyse': 2 },
    ]
  }
}
// const paramsResponse = JSON.parse(localStorage.getItem('parameters'))
const parametersData = parameters ? parameters : []
const consumptionThreshold =
  parametersData.find(
    (param : any) => param?.name == 'Plafond verbruik voor warmtepomp',
  )?.value || 0

if (title == 'Hoofddak') {
  if (pages?.[12]?.answerNo == 0) {
    return [
      // { 'Licht geïsoleerd': 1, 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 },
      { 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 },
    ]
  }
  if (pages?.[12]?.answerNo == 2) {
    return [{ 'Goed geïsoleerd': 3 }]
  }
  return [{ 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 }]
}

if (title == 'Bijdak') {
  if (pages?.[13]?.answerNo == 2) {
    return [{ 'Goed geïsoleerd': 3 }]
  }
  return [{ 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 }]
}

if (title == 'Ramen leefruimte') {
  if (pages?.[15]?.answerNo == 0 || pages?.[15]?.answerNo == 1) {
    return [{ 'Hoogrendementsglas': 2, 'Driedubbel glas': 3 }]
  }
  if (pages?.[15]?.answerNo == 2) {
    return [{   'Driedubbel glas': 3 }]
  }
  return [{'Hoogrendementsglas': 2, 'Driedubbel glas': 3 }]
}

if (title == 'Ramen slaapkamers') {
  if (pages?.[16]?.answerNo == 0 || pages?.[16]?.answerNo == 1) {
    return [{'Hoogrendementsglas': 2, 'Driedubbel glas': 3 }]
  }
  if (pages?.[16]?.answerNo == 2) {
    return [{ 'Driedubbel glas': 3 }]
  }
  return [{'Hoogrendementsglas': 2, 'Driedubbel glas': 3 }]
}

if (title == 'Voorgevel') {
  // if (pages?.[17]?.answerNo == 1 || pages?.[17]?.answerNo == 2) {
  //   return [{ 'Wel geïsoleerd': 0 }]
  // }
  //return [{'Geïsoleerd in de spouw': 2, 'Wel geïsoleerd': 0 }]
  return [{'Wel geïsoleerd': 0 }]
}

if (title == 'Achter- en zijgevels') {
  // if (pages?.[18]?.answerNo == 1 || pages?.[18]?.answerNo == 2) {
  //   return [{ 'Wel geïsoleerd': 0 }]
  // }
  //return [{'Geïsoleerd in de spouw': 2, 'Wel geïsoleerd': 0 }]
  return [{'Wel geïsoleerd': 0 }]
}

if (title == 'Vloerisolatie') {
  if (pages?.[20]?.answerNo == 1 || pages?.[20]?.answerNo == 2) {
    return [{ 'Wel geïsoleerd': 0 }]
  }
  return [{'Gedeeltelijk geïsoleerd': 2, 'Wel geïsoleerd': 0 }]
}


if (title === 'Verwarming') {
  if (pages?.[6]?.answerNo === '0') {
    if (pages?.[7]?.answerNo === '0' || pages?.[7]?.answerNo === '1') {
    
      if (pages?.[28]?.answerNo === '0') {
     console.log('Im in this condition which is true 111111111222222222222222')
        return [
          {
            // Kachels: 0,
            Condensatieketel: 2,
            // 'Hybride ketel': 3,
            // 'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            'Hybride warmtepomp': 5,
            // Warmtenet: 14,
          },
        ]
      }
     

      return [
        {
          // Kachels: 0,
          Condensatieketel: 2,
          // 'Hybride ketel': 3,
          'Warmtepomp bodem/water': 9,
          // 'Warmtepomp water/water': 8,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp lucht/lucht': 6,
          'Hybride warmtepomp': 5,
          // Warmtenet: 14,
        },
      ]
    }
    if (pages?.[7]?.answerNo === '2') {
     
      if (pages?.[28]?.answerNo === '0') {
      

        return [
          {
            'Hybride warmtepomp': 5,
            // 'Hybride ketel': 3,
            // 'Warmtepomp water/water': 8,
            // 'Warmtepomp bodem/water': 9,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
    

      return [
        {
          'Hybride warmtepomp': 5,
          // 'Hybride ketel': 3,
          // 'Warmtepomp water/water': 8,
          'Warmtepomp bodem/water': 9,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp lucht/lucht': 6,
          // Warmtenet: 14,
        },
      ]
    }
    if (pages?.[7]?.answerNo === '3') {
   
      if (pages?.[28]?.answerNo === '0') {
       

        return [
          {
            // 'Hybride warmtepomp': 5,
            // 'Hybride ketel': 3,
            // 'Warmtepomp water/water': 8,
            // 'Warmtepomp bodem/water': 9,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
   

      return [
        {
          // 'Hybride warmtepomp': 5,
          // 'Hybride ketel': 3,
          // 'Warmtepomp water/water': 8,
          'Warmtepomp bodem/water': 9,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp lucht/lucht': 6,
          // Warmtenet: 14,
        },
      ]
    }
  }
  if (pages?.[6]?.answerNo === '1') {
    if (pages?.[7]?.answerNo === '4') {
      if (pages?.[28]?.answerNo === '0') {
      

        return [
          {
            'Hybride warmtepomp': 5,
            'Warmtepomp lucht/lucht': 6,
            'Warmtepomp lucht/water': 7,
            // 'Warmtepomp water/water': 8,
            // Warmtenet: 14,
          },
        ]
      }
   

      return [
        {
          'Hybride warmtepomp': 5,
          'Warmtepomp lucht/lucht': 6,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp bodem/water': 9,
          // 'Warmtepomp water/water': 8,
          // Warmtenet: 14,
        },
      ]
    }

  

    return [
      {
        'Warmtepomp lucht/lucht': 6,
        'Warmtepomp lucht/water': 7,
        'Warmtepomp water/water': 8,
        'Warmtepomp bodem/water': 9,
      },
    ]
  }
  if (pages?.[6]?.answerNo === '2') {
    if (pages?.[7]?.answerNo === '10' || pages?.[7]?.answerNo === '11') {

      if (pages?.[28]?.answerNo === '0') {
       

        return [
          {
            // Kachels: 10,
            Condensatieketel: 2,
            'Hybride warmtepomp': 5,
            // 'Hybride ketel': 13,
            // 'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
      

      return [
        {
          // Kachels: 10,
          Condensatieketel: 2,
          'Hybride warmtepomp': 5,
          // 'Hybride ketel': 13,
          'Warmtepomp bodem/water': 9,
          // 'Warmtepomp water/water': 8,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp lucht/lucht': 6,
          // Warmtenet: 14,
        },
      ]
    }

    if (pages?.[7]?.answerNo === '12') {

      if (pages?.[28]?.answerNo === '0') {
       

        return [
          {
            // Condensatieketel: 12,
            'Hybride warmtepomp': 5,
            // 'Hybride ketel': 13,
            // 'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
     

      return [
        {
          // Condensatieketel: 12,
          'Hybride warmtepomp': 5,
          // 'Hybride ketel': 13,
          'Warmtepomp bodem/water': 9,
          // 'Warmtepomp water/water': 8,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp lucht/lucht': 6,
          // Warmtenet: 14,
        },
      ]
    }
    if (pages?.[7]?.answerNo === '13') {

      if (pages?.[28]?.answerNo === '0') {
      

        return [
          {
            // 'Hybride ketel': 3,
            // 'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
    

      return [
        {
          // 'Hybride ketel': 3,
          'Warmtepomp bodem/water': 9,
          // 'Warmtepomp water/water': 8,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp lucht/lucht': 6,
          // Warmtenet: 14,
        },
      ]
    }
  }
  if (pages?.[6]?.answerNo === '3') {
 
    if (pages?.[7]?.answerNo === '15') {
      if (pages?.[28]?.answerNo === '0') {
     

        return [
          {
            // Pellets: 15,
            // Warmtenet: 14,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
          },
        ]
      }
   

      return [
        {
          // Pellets: 15,
          // Warmtenet: 14,
          'Warmtepomp bodem/water': 9,
          // 'Warmtepomp water/water': 8,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp lucht/lucht': 6,
        },
      ]
    }
    if (pages?.[7]?.answerNo === '16') {

      if (pages?.[28]?.answerNo === '0') {
      

        return [
          {
            // Hout: 16,
            // Warmtenet: 14,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
          },
        ]
      }
     
      return [
        {
          // Hout: 16,
          // Warmtenet: 14,
          'Warmtepomp bodem/water': 9,
          // 'Warmtepomp water/water': 8,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp lucht/lucht': 6,
        },
      ]
    }
  }

  return [
    {
      Hout: 16,
      Pellets: 15,
      // Warmtenet: 14,
      'Warmtepomp lucht/water': 7,
      'Warmtepomp water/water': 8,
      'Warmtepomp bodem/water': 9,
    },
  ]
}

if (title == 'Ventilatie') {
  if (pages?.[21]?.answerNo == 0) {
  
    return [
      {
         
        'Vraaggestuurd mechanisch (C)': 3,
        'Balansventilatie met warmterecuperatie (D)': 4,
      },
    ]
  }
  if (pages?.[21]?.answerNo == 1 || pages?.[21]?.answerNo == 2) {
  
    return [
      {
         
        'Vraaggestuurd mechanisch (C)': 3,
        'Balansventilatie met warmterecuperatie (D)': 4,
      },
    ]
  }
  if (pages?.[21]?.answerNo == 3) {
  
    return [
      {
         
        'Balansventilatie met warmterecuperatie (D)': 4,
      },
    ]
  }

  return [
    {
       
      'Natuurlijk met raamroosters': 1,
      'Minimaal mechanisch (C)': 2,
      'Vraaggestuurd mechanisch (C)': 3,
      'Balansventilatie met warmterecuperatie (D)': 4,
    },
  ]
}

if (title == 'Warm water') {
  if (pages?.[11]?.answerNo == 0) {


    return [{   'Idem als verwarming': 0 }]
  }
  if (pages?.[11]?.answerNo == 3 || pages?.[11]?.answerNo == 4) {


    return [{   Warmtepompboiler: 2 }]
  }

  return [
    {
      // Gasdoorstromer: 4,
      // Zonneboiler: 1,
       
      Warmtepompboiler: 2,
      // 'Idem als verwarming': 0,
    },
  ]
}

if (title == 'Zonnepanelen') {


  return [{'Wel zonnepanelen': 1 }]
}

if (title == 'Groendak') {


  return [{'Wel een groendak': 0 }]
}

if (title == 'Zonnewering') {
  if (
    pages?.[6]?.answerNo == 1 &&
    (pages?.[7]?.answerNo == 2 ||
      pages?.[7]?.answerNo == 3 ||
      pages?.[7]?.answerNo == 4 ||
      pages?.[7]?.answerNo == 5)
  ) {


    return [{'Zorg voor zonnewering': 2, 'Plaats geveltuin': 3, Koeling: 4 }]
  }


  return [{'Zorg voor zonnewering': 2, 'Plaats geveltuin': 3 }]
}

if (title == 'Regenput') {

  if(pages?.[1]?.answerNo == 2 && pages?.[29]?.answerNo == 1 ){
    return [{'Plaats een regenwaterzak': 0 }]
  }else if((pages?.[1]?.answerNo == 0 || pages?.[1]?.answerNo == 1) && pages?.[29]?.answerNo == 1 ){
    return [{'Plaats een regenwaterput': 0 }]
  } else {
    return [{'Wel een regenwaterput': 0 }]
  }
}

if (pageIndex == 30) {


  return [{"Onthard": 1 }]
}
return [{}]
}
export function getModalSelectedOption(title: any, pages:any, pageIndex : any, parameters: any) {
  
const consumptions = Number(localStorage.getItem('energyGasNew'))

const parametersData = parameters

const consumptionThreshold =
  parametersData.find(
    (param: any) => param?.name == 'Plafond verbruik voor warmtepomp',
  )?.value || 0

if (pageIndex === 33 || pageIndex == 34) {
  if (pages?.[pageIndex]?.answerNo == 2) {
    return 2;
  }
  return 1;
}

if (pageIndex === 30) {
  return 1;
}
switch (title) {
  case 'Hoofddak':
  case 'Bijdak':
  case 'Ramen leefruimte':
  case 'Ramen slaapkamers':
    return 3;
  case 'Voorgevel':
  case 'Vloerisolatie':
    return 0;
  case 'Achter- en zijgevels':
    return 0;
  case 'Verwarming':
    if (pages?.[6]?.answerNo == 0) {
      if (pages?.[7]?.answerNo == 0 || pages?.[7]?.answerNo == 1) {
        console.log('consumptionThreshold');
        console.log(consumptions, consumptionThreshold);
        if (consumptions > consumptionThreshold) {
          return 2
        }
        if (pages?.[28]?.answerNo == 0) {
          return 6
        }
        return 9
      }
      if (pages?.[7]?.answerNo == 2) {
        if (consumptions > consumptionThreshold) {
          return 5
        }
        if (pages?.[28]?.answerNo == 0) {
          return 7
        }
          return 9
      }
      if (pages?.[7]?.answerNo == 3) {
        if (pages?.[28]?.answerNo == 0) {
          return 7
        }
        return 9
      }
        return 8
      }
    if (pages?.[6]?.answerNo == 1) {
      if (pages?.[7]?.answerNo == 4) {
        if (pages?.[28]?.answerNo == 0) {
          return 6
        }
  
        return 9
        }
        return 7
      }
    if (pages?.[6]?.answerNo == 2) {
      if (
        pages?.[7]?.answerNo == 0 ||
        pages?.[7]?.answerNo == 10 ||
        pages?.[7]?.answerNo == 1 ||
        pages?.[7]?.answerNo == 11
      ) {
     
          if (consumptions > consumptionThreshold) {
  
            return 2 // can be 2
          }
          if (pages?.[28]?.answerNo == 0) {
    
            return 6
          }
  
          return 9
        }
  
      if (pages?.[7]?.answerNo == 2 || pages?.[7]?.answerNo == 12) {
        if (consumptions > consumptionThreshold) {
         return 5
        }
        if (pages?.[28]?.answerNo == 0) {
          return 7
        }
        return 9
        }
      if (pages?.[7]?.answerNo == 3 || pages?.[7]?.answerNo == 13) {
        if (pages?.[28]?.answerNo == 0) {
  
          return 7
        }
        return 9
        }
        return 10
      }
    if (pages?.[6]?.answerNo == 3) {
      if (pages?.[7]?.answerNo == 1 || pages?.[7]?.answerNo == 15) {
        if (pages?.[28]?.answerNo == 0) {
          return 6
        }
        return 9
        }
      if (pages?.[7]?.answerNo == 2 || pages?.[7]?.answerNo == 16) {
        if (pages?.[28]?.answerNo == 0) {
          return 6
        }
  
        return 9
        }
  
  
        return 16
    }
    break;
  case 'Ventilatie':
    return pages?.[21]?.answerNo == 3 ? 4 : 3;
  case 'Warm water':
    return pages?.[11]?.answerNo == 0 ? 0 : 2;
  case 'Zonnepanelen':
    return 1;
  case 'Groendak':
    return 0;
  case 'Regenput':
    return 0;
  case 'Zonnewering':
    return 2;
  default:
    return false;
}
}


export function getSelectedOptFun(dropDownOptions : any, selectedOption : any) {
const dropDown = dropDownOptions[0]
const optVal = Number(selectedOption)
if (dropDownOptions) {
  const rVal = Object.keys(dropDown).filter(
    (dp) => Number(dropDown[dp]) === optVal,
  )
  if(rVal.length > 1){
    return rVal[1]
  }
  else{
  return rVal[0]
  }
}
return ''
}
export function isEmptyObj(obj = {}) {
  if(obj) {
    return !Object.keys(obj)?.length
  }
  else {
    return false;
  }
}

export function appendRoute(pageNo:any, page6AnswerNumber:any, propTitle:any) {
  let title = propTitle || ''
  if (pageNo == 7) {
    title = getQuestionKey(pageNo, page6AnswerNumber).replace(/\_/g, '')
  } else {
    //@ts-ignore
    title = pageTitles[`questionnaire${pageNo}`]
      .split('|')[0]
      .replace(/\ /g, '')
      .toLowerCase()
  }
  return `/vraag/${pageNo}/${title}`
}
export function getAdviceKey(status:any, title:any, answerNo:any, selectedOption:any) {
  const titleNoSpace = title?.replace(/\s+/g, '_')
  const ansNo = Number(answerNo) + 1
  if (status.toLowerCase() == 'green' || status.toLowerCase() == 'green') {
    return `${titleNoSpace}_${ansNo}_advice_Green`
  }
  if (status.toLowerCase() == 'midgreen') {
    return `${titleNoSpace}_${ansNo}_advice_Green`
  }
  if (status.toLowerCase() == 'orange') {
    return `${titleNoSpace}_${ansNo}_advice_Orange`
  }
  if (status.toLowerCase() == 'red') {
    return `${titleNoSpace}_${ansNo}_advice_Red`
  }
  return ''
}

export function getAdviceKeyXL(status:any, title:any, answerNo:any, selectedOption:any) {
  const titleNoSpace = title?.replace(/\s+/g, '_')
  const ansNo = Number(answerNo) + 1
  if (status.toLowerCase() == 'green' || status.toLowerCase() == 'green') {
    return `${titleNoSpace}_${ansNo}_advice_GreenXL`
  }
  if (status.toLowerCase() == 'orange') {
    return `${titleNoSpace}_${ansNo}_advice_OrangeXL`
  }
  if (status.toLowerCase() == 'red') {
    return `${titleNoSpace}_${ansNo}_advice_RedXL`
  }
  return ''
}

export function stPage12(pages:any, log:any) {
  if (
    // (pages[14]?.answerNo == 0 ||
    //   pages[14]?.answerNo == 1 ||
    //   pages[14]?.answerNo == 2) &&
    pages[12]?.answerNo == 0 ||
    pages[12]?.answerNo == 1
  ) {
    if (log == 1) {
      return `
    (
      (pages[14]?.answerNo == 0 ||
      pages[14]?.answerNo == 1 ||
      pages[14]?.answerNo == 2
    ) &&
      (pages[12]?.answerNo == 0 || pages[12]?.answerNo == 1)
    )`
    }

    return 'red'
  }
  if (
    // (pages[14]?.answerNo == 0 || pages[14]?.answerNo == 2) &&
    pages[12]?.answerNo == 2
  ) {
    if (log == 1) {
      return '((pages[14]?.answerNo == 0 || pages[14]?.answerNo == 2) && pages[12]?.answerNo == 2) '
    }

    return 'orange'
  }
  if (pages[12]?.answerNo == 2) {
    if (log == 1) {
      return 'pages[14]?.answerNo == 1 && pages[12]?.answerNo == 2'
    }

    return 'midgreen'
  }
  if (log == 1) {
    return 'Default'
  }
  return 'green'
}
export function stPage13(pages:any, log:any) {
  if (
    // (pages[14]?.answerNo == 0 ||
    //   pages[14]?.answerNo == 1 ||
    //   pages[14]?.answerNo == 2) &&
    pages[13]?.answerNo == 0 ||
    pages[13]?.answerNo == 1
  ) {
    if (log == 1) {
      return `
    (
    (
      pages[14]?.answerNo == 0 ||
      pages[14]?.answerNo == 1 ||
      pages[14]?.answerNo == 2
    ) &&
    (pages[13]?.answerNo == 0 || pages[13]?.answerNo == 1)
    )`
    }

    return 'red'
  }
  if (
    // (pages[14]?.answerNo == 1 || pages[14]?.answerNo == 2) &&
    pages[13]?.answerNo == 2
  ) {
    if (log == 1) {
      return `
    (
      (pages[14]?.answerNo == 1 || pages[14]?.answerNo == 2) 
    &&
      pages[13]?.answerNo == 2
    )`
    }

    return 'orange'
  }
  if (pages[13]?.answerNo == 2) {
    if (log == 1) {
      return 'pages[14]?.answerNo == 0 && pages[13]?.answerNo == 2'
    }

    return 'midgreen'
  }
  if (log == 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage15(pages:any, log:any) {
  if (
    ((pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
      (pages[15]?.answerNo == 0 || pages[15]?.answerNo == 1)) ||
    ((pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
      pages[15]?.answerNo == 0)
  ) {
    if (log == 1) {
      return `
    (
      ((pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
        (pages[15]?.answerNo == 0 || pages[15]?.answerNo == 1)) ||
      ((pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
        pages[15]?.answerNo == 0)
    )
    `
    }

    return 'red'
  }
  if (
    (pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
    pages[15]?.answerNo == 1
  ) {
    if (log == 1) {
      return `
    (
      (pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
      pages[15]?.answerNo == 1
    )
    `
    }

    return 'orange'
  }
  if (
    (pages[9]?.answerNo == 0 ||
      pages[9]?.answerNo == 1 ||
      pages[9]?.answerNo == 2 ||
      pages[9]?.answerNo == 3) &&
    pages[15]?.answerNo == 2
  ) {
    if (log == 1) {
      return `
    (
      (pages[9]?.answerNo == 0 ||
        pages[9]?.answerNo == 1 ||
        pages[9]?.answerNo == 2 ||
        pages[9]?.answerNo == 3) &&
      pages[15]?.answerNo == 2
    )
    `
    }

    return 'midgreen'
  }
  if (log == 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage16(pages:any, log:any) {
  if (
    ((pages[10]?.answerNo == 0 || pages[10]?.answerNo == 1) &&
      (pages[16]?.answerNo == 0 || pages[16]?.answerNo == 1)) ||
    ((pages[10]?.answerNo == 2 || pages[10]?.answerNo == 3) &&
      pages[16]?.answerNo == 0)
  ) {
    if (log == 1) {
      return `
    (
      ((pages[10]?.answerNo == 0 || pages[10]?.answerNo == 1) &&
        (pages[16]?.answerNo == 0 || pages[16]?.answerNo == 1)) ||
      ((pages[10]?.answerNo == 2 || pages[10]?.answerNo == 3) &&
        pages[16]?.answerNo == 0)
    )
    `
    }

    return 'red'
  }
  if (
    (pages[10]?.answerNo == 2 || pages[10]?.answerNo == 3) &&
    pages[16]?.answerNo == 1
  ) {
    if (log == 1) {
      return `
    (
      (pages[10]?.answerNo == 2 || pages[10]?.answerNo == 3) &&
      pages[16]?.answerNo == 1
    )
    `
    }

    return 'orange'
  }
  if (
    (pages[10]?.answerNo == 0 ||
      pages[10]?.answerNo == 1 ||
      pages[10]?.answerNo == 2 ||
      pages[10]?.answerNo == 3) &&
    pages[16]?.answerNo == 2
  ) {
    if (log == 1) {
      return `
    (
      (pages[10]?.answerNo == 0 ||
        pages[10]?.answerNo == 1 ||
        pages[10]?.answerNo == 2 ||
        pages[10]?.answerNo == 3) &&
      pages[16]?.answerNo == 2
    )
    `
    }

    return 'midgreen'
  }
  if (log == 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage17(pages:any, log:any) {
  if (
    (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
    pages[17]?.answerNo == 1
  ) {
    if (log == 1) {
      return `
    (
      (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
      pages[17]?.answerNo == 1
    )
    `
    }

    return 'red'
  }
  if (
    (pages[9]?.answerNo == 0 && pages[17]?.answerNo == 2) ||
    ((pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
      pages[17]?.answerNo == 1)
  ) {
    if (log == 1) {
      return `
    (
      (pages[9]?.answerNo == 0 && pages[17]?.answerNo == 2) ||
      ((pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
        pages[17]?.answerNo == 1)
    )
    `
    }

    return 'orange'
  }
  if (
    (pages[9]?.answerNo == 1 ||
      pages[9]?.answerNo == 2 ||
      pages[9]?.answerNo == 3) &&
    pages[17]?.answerNo == 2
  ) {
    if (log == 1) {
      return `
    (
      (pages[9]?.answerNo == 1 ||
        pages[9]?.answerNo == 2 ||
        pages[9]?.answerNo == 3) &&
      pages[17]?.answerNo == 2
    )
    `
    }

    return 'midgreen'
  }
  if (log == 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage18(pages:any, log:any) {
  const colors = ['red', 'orange', 'green', 'green']

  const getPriority = (pageNo:any) => {
    if (
      (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
      pages[pageNo]?.answerNo == 1
    ) {
      return 0
    }
    if (
      (pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
      (pages[pageNo]?.answerNo == 1 || pages[pageNo]?.answerNo == 2)
    ) {
      return 1
    }
    if (
      (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
      pages[pageNo]?.answerNo == 2
    ) {
      return 2
    }

    return 3
  }
  const page18Priority = getPriority(18)
  const page19Priority = getPriority(19)

  if (page18Priority < page19Priority) {
    if (log == 1) {
      return `
    Colors : ['red', 'orange', 'green', 'green']
    Page priority : page18Priority < page19Priority
    Logic :
    if (
      (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
      pages[pageNo]?.answerNo == 1
    ) {
      return 0
    }
    if (
      (pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
      (pages[pageNo]?.answerNo == 1 || pages[pageNo]?.answerNo == 2)
    ) {
      return 1
    }
    if (
      (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
      pages[pageNo]?.answerNo == 2
    ) {
      return 2
    }

    return 3
    `
    }

    return { status: colors[page18Priority], pageNo: 18 }
  }

  if (page19Priority < page18Priority) {
    if (log == 1) {
      return `
    Colors : ['red', 'orange', 'green', 'green']
    Page priority : page19Priority < page18Priority
    Logic :
    if (
      (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
      pages[pageNo]?.answerNo == 1
    ) {
      return 0
    }
    if (
      (pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
      (pages[pageNo]?.answerNo == 1 || pages[pageNo]?.answerNo == 2)
    ) {
      return 1
    }
    if (
      (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
      pages[pageNo]?.answerNo == 2
    ) {
      return 2
    }

    return 3
    `
    }

    return { status: colors[page19Priority], pageNo: 19 }
  }

  if (log == 1) {
    return `
  Colors : ['red', 'orange', 'green', 'green']
  Page priority : Default page18
  Logic :
  if (
    (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
    pages[pageNo]?.answerNo == 1
  ) {
    return 0
  }
  if (
    (pages[9]?.answerNo == 2 || pages[9]?.answerNo == 3) &&
    (pages[pageNo]?.answerNo == 1 || pages[pageNo]?.answerNo == 2)
  ) {
    return 1
  }
  if (
    (pages[9]?.answerNo == 0 || pages[9]?.answerNo == 1) &&
    pages[pageNo]?.answerNo == 2
  ) {
    return 2
  }

  return 3
  `
  }
  return { status: colors[page18Priority], pageNo: 18 }
}

export function stPage20(pages:any, log:any) {
  if (pages[20]?.answerNo == 1 || pages[20]?.answerNo == 2) {
    if (log == 1) {
      return 'pages[20]?.answerNo == 1 || pages[20]?.answerNo == 2'
    }
    return 'orange'
  }
  if (log == 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage21(pages:any, log:any) {
  if (pages[21]?.answerNo == 0) {
    if (log == 1) {
      return 'pages[21]?.answerNo == 0'
    }

    return 'red'
  }
  if (pages[21]?.answerNo == 1 || pages[21]?.answerNo == 2) {
    if (log == 1) {
      return 'pages[21]?.answerNo == 1 || pages[21]?.answerNo == 2'
    }

    return 'orange'
  }
  if (pages[21]?.answerNo == 3) {
    if (log == 1) {
      return 'pages[21]?.answerNo == 3'
    }

    return 'midgreen'
  }
  if (log == 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage22(pages:any, log:any) {
  if (pages[22]?.answerNo == 1) {
    if (log == 1) {
      return 'ages[22]?.answerNo == 1'
    }

    return 'red'
  }
  if (log == 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage27(pages:any, log:any) {
  if (pages[27]?.answerNo == 1) {
    if (log == 1) {
      return 'pages[27]?.answerNo == 1'
    }
    return 'red'
  }

  if (log == 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage31(pages:any, log:any) {
  if (pages[31]?.answerNo == 0) {
    if (log == 1) {
      return 'pages[31]?.answerNo == 0'
    }

    return 'red'
  }
  if (log == 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage29(pages:any, log:any) {
  if (pages[29]?.answerNo == 1) {
    if (log == 1) {
      return 'pages[29]?.answerNo == 1'
    }
    return 'red'
  }

  if (log == 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage30(pages:any, log:any) {
  if (pages[30]?.answerNo == 0) {
    if (log == 1) {
      return 'pages[30]?.answerNo == 0'
    }
    return 'red'
  }
  if (log == 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage7(pages:any, log:any) {
  if (
    (pages[6]?.answerNo == 0 &&
      (pages[7]?.answerNo == 0 || pages[7]?.answerNo == 1)) ||
    (pages[6]?.answerNo == 1 && pages[7]?.answerNo == 0) ||
    (pages[6]?.answerNo == 2 &&
      (pages[7]?.answerNo == 0 || pages[7]?.answerNo == 1)) ||
    (pages[6]?.answerNo == 3 && pages[7]?.answerNo == 2)
  ) {
    if (log == 1) {
      return ` 
          (pages[6]?.answerNo == 0 &&
          (pages[7]?.answerNo == 0 || pages[7]?.answerNo == 1)) ||
          (pages[6]?.answerNo == 1 && pages[7]?.answerNo == 0) ||
          (pages[6]?.answerNo == 2 &&
          (pages[7]?.answerNo == 0 || pages[7]?.answerNo == 1)) ||
          (pages[6]?.answerNo == 3 && pages[7]?.answerNo == 2)`
    }
    return 'red'
  }
  if (
    (pages[6]?.answerNo == 0 &&
      (pages[7]?.answerNo == 2 || pages[7]?.answerNo == 3)) ||
    (pages[6]?.answerNo == 1 && pages[7]?.answerNo == 1) ||
    (pages[6]?.answerNo == 2 &&
      (pages[7]?.answerNo == 2 || pages[7]?.answerNo == 3)) ||
    (pages[6]?.answerNo == 3 && pages[7]?.answerNo == 1)
  ) {
    if (log == 1) {
      return ` 
          (pages[6]?.answerNo == 0 &&
          (pages[7]?.answerNo == 2 || pages[7]?.answerNo == 3)) ||
          (pages[6]?.answerNo == 1 && pages[7]?.answerNo == 1) ||
          (pages[6]?.answerNo == 2 &&
          (pages[7]?.answerNo == 2 || pages[7]?.answerNo == 3)) ||
          (pages[6]?.answerNo == 3 && pages[7]?.answerNo == 1)`
    }

    return 'orange'
  }
  if (log == 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage11(pages:any, prevPages:any, log:any) {
  if (pages[11]?.answerNo == 3) {
    if (log == 1) {
      return 'pages[11]?.answerNo == 3'
    }

    return 'red'
  }
  if (pages[11]?.answerNo == 4) {
    if (log == 1) {
      return 'pages[11]?.answerNo == 4'
    }

    return 'orange'
  }
  if (pages[11]?.answerNo == 0 && prevPages[11]?.answerNo == 0) {
    if (
      (pages[6]?.answerNo == 0 &&
        (pages[7]?.answerNo == 0 || pages[7]?.answerNo == 1)) ||
      (pages[6]?.answerNo == 1 && pages[7]?.answerNo == 0) ||
      (pages[6]?.answerNo == 2 &&
        (pages[7]?.answerNo == 0 || pages[7]?.answerNo == 1)) ||
      (pages[6]?.answerNo == 3 && pages[7]?.answerNo == 2)
    ) {
      if (log == 1) {
        return `
      (pages[11]?.answerNo == 0 && prevPages[11]?.answerNo == 0) && (
      (pages[6]?.answerNo == 0 &&
      (pages[7]?.answerNo == 0 || pages[7]?.answerNo == 1)) ||
      (pages[6]?.answerNo == 1 && pages[7]?.answerNo == 0) ||
      (pages[6]?.answerNo == 2 &&
      (pages[7]?.answerNo == 0 || pages[7]?.answerNo == 1)) ||
      (pages[6]?.answerNo == 3 && pages[7]?.answerNo == 2))
      `
      }

      return 'red'
    }
    if (
      (pages[6]?.answerNo == 0 &&
        (pages[7]?.answerNo == 2 || pages[7]?.answerNo == 3)) ||
      (pages[6]?.answerNo == 1 && pages[7]?.answerNo == 1) ||
      (pages[6]?.answerNo == 2 &&
        (pages[7]?.answerNo == 2 || pages[7]?.answerNo == 3)) ||
      (pages[6]?.answerNo == 3 && pages[7]?.answerNo == 1)
    ) {
      if (log == 1) {
        return `
      (pages[11]?.answerNo == 0 && prevPages[11]?.answerNo == 0) && (
      (pages[6]?.answerNo == 0 &&
      (pages[7]?.answerNo == 2 || pages[7]?.answerNo == 3)) ||
      (pages[6]?.answerNo == 1 && pages[7]?.answerNo == 1) ||
      (pages[6]?.answerNo == 2 &&
      (pages[7]?.answerNo == 2 || pages[7]?.answerNo == 3)) ||
      (pages[6]?.answerNo == 3 && pages[7]?.answerNo == 1)
      `
      }
      return 'orange'
    }
  }
  if (log == 1) {
    return 'Default'
  }
  return 'green'
}
export async function setCardsUpdateLog(
  card:any,
  userId:any,
  cookiesPages:any,
  previousCookies:any,
  answer_id:any,
  answer:any,
) {
  var statusLogs
  var status
  const obj = []
  if (card == 'card1') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage12(cookiesPages, 1)),
      (status = stPage12(cookiesPages, 0))
  }
  if (card == 'card2') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage13(cookiesPages, 1)),
      (status = stPage13(cookiesPages, 0))
  }
  if (card == 'card3') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage15(cookiesPages, 1)),
      (status = stPage15(cookiesPages, 0))
  }
  if (card == 'card4') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage16(cookiesPages, 1)),
      (status = stPage16(cookiesPages, 0))
  }
  if (card == 'card5') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage17(cookiesPages, 1)),
      (status = stPage17(cookiesPages, 0))
  }
  if (card == 'card6') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage18(cookiesPages, 1)),
      (status = stPage18(cookiesPages, 0))
  }
  if (card == 'card7') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage20(cookiesPages, 1)),
      (status = stPage20(cookiesPages, 0))
  }
  if (card == 'card8') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage7(cookiesPages, 1)),
      (status = stPage7(cookiesPages, 0))
  }
  if (card == 'card9') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage11(cookiesPages, previousCookies, 1)),
      (status = stPage11(cookiesPages, previousCookies, 0))
  }
  if (card == 'card10') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage21(cookiesPages, 1)),
      (status = stPage21(cookiesPages, 0))
  }
  if (card == 'card11') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage22(cookiesPages, 1)),
      (status = stPage22(cookiesPages, 0))
  }
  if (card == 'card12') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage27(cookiesPages, 1)),
      (status = stPage27(cookiesPages, 0))
  }
  if (card == 'card13') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage31(cookiesPages, 1)),
      (status = stPage31(cookiesPages, 0))
  }
  if (card == 'card14') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage29(cookiesPages, 1)),
      (status = stPage29(cookiesPages, 0))
  }
  if (card == 'card15') {
   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(statusLogs = stPage30(cookiesPages, 1)),
      (status = stPage30(cookiesPages, 0))
  }

  const statusText = `Card's Update\r\n*********************
    => New answer : ${answer_id} - ${answer}
    => New status : ${status}
    => New status Because : ${statusLogs}\r\n\r\n`

  obj.push({
    logs: statusText,
    card_id: card,
    userId,
  })

  // axios.post(`${process.env.REACT_APP_BACKEND_URL}/logs/addCardsLogs`, { obj })

  // setTimeout(() => {
  //   data = dataStatus
  //   axios.post(`${process.env.REACT_APP_BACKEND_URL}/logs/addCardsLogs`, { data })
  // }, 5000)
}

export const handleSubmitModal = (
  values:any,
  answerKey:any,
  selectedText:any,
  area:any,
  calculations = {},
  inputValue:any,
  parameters:any,
  // selectedOption:any,

  componentProps:any,
) => {
  const {
    userId,
    localImprovements,
    pageNo,
    key,
    setRenderUpdate,
    renderUpdate
    // dispatch,
    // setCookies,
    // localState,
  } = componentProps

  console.log('==>', values,
  answerKey,
  selectedText,
  calculations,
  inputValue,
  area)
  const data = localImprovements
  const answerNo = Number(inputValue)
  // console.log(pageNo,'pageNo')
  const improvedData =  {
        ...data,
        [pageNo]: {
          answerNo:inputValue,
          [key]: selectedText,
          key:key,
          area
        },
      }
      console.log("improvedData",improvedData)

  const updatedData =
    pageNo !== '18' && pageNo !== '22'
      ? {
          ...data,
          [pageNo]: {
            answerNo,
            [key]: selectedText,
            fromImprovement: true,
          },
        }
      : pageNo == '22'
      ? {
          ...data,
          22: {
            answerNo,
            [key]: selectedText,
            fromImprovement: true,
          },
          23: {
            aantal_zonnepanelen: Number(values.oppervlakte),
            fromImprovement: true,
          },
        }
      : {
          ...data,
          18: {
            answerNo,
            [key]: selectedText,
            fromImprovement: true,
          },
          19: {
            answerNo,
            [key]: selectedText,
            fromImprovement: true,
          },
        }
  // const paramsResponse = JSON.parse(localStorage.getItem('parameters')!)
  // const parameters = paramsResponse?.data?.data?.[0] || []
  const gasParam =
    parameters.find((param:any) => param?.name == 'CO2/kWh voor gas')?.value || 0
  const electParam =
    parameters.find((param:any) => param?.name == 'CO2/kWh voor elektriciteit')
      ?.value || 0
  const co2 = Number(
    //@ts-ignore
    calculations?.pagesEnergyGas * gasParam +
    //@ts-ignore
      calculations?.pagesEnergyElec * electParam,
  ).toFixed(2);

  let existingInvester = JSON.parse(localStorage?.getItem('Invester')!);

  let keyInvester  = existingInvester?.hasOwnProperty(key)
  // console.log(keyInvester,'keyInvester');
  //@ts-ignore
  let invester:any = {[key]: calculations?.investeer < 0 ? 0 : calculations?.investeer}
  // console.log(invester,'invester')
  if(existingInvester ){
     //@ts-ignore
    existingInvester = {...existingInvester, [key]: calculations?.investeer < 0 ? 0 : calculations?.investeer}
    // console.log(existingInvester,'existingInvester')
    localStorage.setItem('Invester', JSON.stringify(existingInvester))
  }
  else{
  localStorage.setItem('Invester', JSON.stringify(invester))
  }
  

  let existingBespaar = JSON.parse(localStorage.getItem('Bespaar')!);
  //@ts-ignore
  // console.log(calculations?.bespaar,'calculations?.bespaar')
//@ts-ignore
  let bespaar:any = {[key]: Number(calculations?.bespaar)}
  if(existingBespaar){
    //@ts-ignore
    existingBespaar = {...existingBespaar, [key]: Number(calculations?.bespaar)};
    localStorage.setItem('Bespaar', JSON.stringify(existingBespaar))
  }
  else{
  localStorage.setItem('Bespaar', JSON.stringify(bespaar))
  }


  let existingPremies = JSON.parse(localStorage.getItem('Premies')!);
//@ts-ignore
  let premies:any = {[key]: calculations?.premies < 0 ? 0 : calculations?.premies }
  if(existingPremies){
     //@ts-ignore
    existingPremies =  {...existingPremies, [key]: calculations?.premies < 0 ? 0 : calculations?.premies};
    localStorage.setItem('Premies', JSON.stringify(existingPremies))
  }
  else{
    localStorage.setItem('Premies', JSON.stringify(premies))
  }



  let existingCo2 = JSON.parse(localStorage.getItem('Co2')!);
//@ts-ignore
  let Co2:any = {[key]: co2 < 0 ? 0 : co2}
  if(existingCo2){
    //@ts-ignore
    existingCo2 = {...existingCo2,[key]: co2 < 0 ? 0 : co2};
    localStorage.setItem('Co2', JSON.stringify(existingCo2))
  }
  else{
  localStorage.setItem('Co2', JSON.stringify(Co2))
  }


  let existingMinder = JSON.parse(localStorage.getItem('MinderEnergie')!);
  //@ts-ignore
  let minderEnergie:any = {[key]: calculations?.minderEnergie < 0 ? 0 : calculations?.minderEnergie}
  if(existingMinder){
     //@ts-ignore
    existingMinder = {...existingMinder,[key]: calculations?.minderEnergie < 0 ? 0 : calculations?.minderEnergie};
    localStorage.setItem("MinderEnergie",JSON.stringify(existingMinder));
  }
  else{
  localStorage.setItem('MinderEnergie', JSON.stringify(minderEnergie))
  }



  let existingElec = JSON.parse(localStorage.getItem('PagesEnergyElec')!);
  //@ts-ignore
  // console.log(calculations?.pagesEnergyElec,'calculations?.pagesEnergyElec')
  //@ts-ignore
  let pagesEnergyElec:any = {[key]: calculations?.pagesEnergyElec}
  if(existingElec){
      //@ts-ignore
    existingElec = {...existingElec,[key]: calculations?.pagesEnergyElec};
    localStorage.setItem('PagesEnergyElec',JSON.stringify(existingElec));
  }
  else{
  localStorage.setItem('PagesEnergyElec', JSON.stringify(pagesEnergyElec))
  }


  let existingGas = JSON.parse(localStorage.getItem('PagesEnergyGas')!);
  //@ts-ignore
  let pagesEnergyGas:any = {[key]: calculations?.pagesEnergyGas < 0 ? 0 : calculations?.pagesEnergyGas}

  if(existingGas){
     //@ts-ignore
    existingGas = {...existingGas,[key]: calculations?.pagesEnergyGas < 0 ? 0 : calculations?.pagesEnergyGas};
    localStorage.setItem('PagesEnergyGas',JSON.stringify(existingGas))
  }
  else{
  localStorage.setItem('PagesEnergyGas', JSON.stringify(pagesEnergyGas))
  }


  let existingVerkoop = JSON.parse(localStorage.getItem('VerkoopElec')!);
 
  //@ts-ignore
  let verkoopElec:any = {[key]: calculations?.verkoopElec}
  if(existingVerkoop){
      //@ts-ignore
    existingVerkoop = {...existingVerkoop,[key]: calculations?.verkoopElec};
    localStorage.setItem('VerkoopElec', JSON.stringify(existingVerkoop))
  }
  else{
  localStorage.setItem('VerkoopElec', JSON.stringify(verkoopElec))
  }
 
setRenderUpdate(true);
// console.log(updatedData,'updatedData')
  sendConsumptions(userId, localImprovements, updatedData)
  localStorage.setItem('localImprovements', JSON.stringify(updatedData));

  const improvedCard  = localStorage.getItem('improvedCard');

  if(improvedCard){
    const improved = JSON.parse(improvedCard);
    Object.assign(improved, improvedData)
    localStorage.setItem('improvedCard', JSON.stringify(improved));

  }else{
    localStorage.setItem('improvedCard', JSON.stringify(improvedData));
  }

  if(renderUpdate === true){
    setRenderUpdate(false);
  }
  else{
    setRenderUpdate(true);
  } 
  // setRenderUpdate(false);
  // improvementPostCall(
  //   userId,
  //   key,
  //   `selected option is ${selectedText} & input value is ${inputValue}`,
  // )
}
export async function sendConsumptions(userId:any, pages:any, improvedPages:any) {
  if (!isEmptyObj(pages) && !isEmptyObj(improvedPages)) {
    const currentPayload = await getCalculationPayload(pages)
    const improvedPayload = await getCalculationPayload(improvedPages, true)
    const storedCurrentPayload = localStorage.getItem('currentPayload')
    const storeImprovedPayload = localStorage.getItem('improvedPayload')
    const storedResult = localStorage.getItem('result')

    const checkCurrentPayload =
      JSON.stringify(currentPayload) == storedCurrentPayload
    const checkImprovedPayload =
      JSON.stringify(improvedPayload) == storeImprovedPayload

    if (checkCurrentPayload && checkImprovedPayload) {
      const result = JSON.parse(storedResult!)
      if (result) {
        const { current } = result.data
        const { newMeasures } = result.data
        const currentConsumption = current.energyUseGas + current.energyUseElec
        const futureConsumption =
          newMeasures.adjustedScaledGas + newMeasures.adjustedScaledElec

        sessionPutCall(userId, {
          current_consumption: currentConsumption,
          future_consumption: futureConsumption,
        })
      }
    }
  }
}
export function improvementPostCall(userId:any, questionText:any, answer:any) {
  axios.post(`${process.env.REACT_APP_BACKEND_URL}/session/improvement`, {
    improvement_text: questionText,
    selected_improvement: answer,
    sessionId: userId,
  })
  sessionPutCall(userId, {})
}

export function sessionPutCall(userId:any, data:any) {
  axios.put(`${process.env.REACT_APP_BACKEND_URL}/session/${userId}`, data)
}
export const handleRemoveChanges = (componentProps:any) => {
  const {
    userId,
    localImprovements,
    localState,
    pageNo,
    key,
    setRenderUpdate,
    renderUpdate
  } = componentProps

  const data = localImprovements
  const reSetData =
    pageNo !== '18'
      ? {
          ...data,
          [pageNo]: {
            ...localState?.[pageNo],
          },
        }
      : {
          ...data,
          18: {
            ...localState?.[18],
          },
          19: {
            ...localState?.[19],
          },
        }
  if (componentProps.pageNo === '12') {
    setCardsUpdateLog(
      'card1',
      userId,
      reSetData,
      data,
      reSetData[12].answerNo,
      reSetData[12][key],
    )
  }
  if (componentProps.pageNo === '13') {
    setCardsUpdateLog(
      'card2',
      userId,
      reSetData,
      data,
      reSetData[13].answerNo,
      reSetData[13][key],
    )
  }
  if (componentProps.pageNo === '15') {
    setCardsUpdateLog(
      'card3',
      userId,
      reSetData,
      data,
      reSetData[15].answerNo,
      reSetData[15][key],
    )
  }
  if (componentProps.pageNo === '16') {
    setCardsUpdateLog(
      'card4',
      userId,
      reSetData,
      data,
      reSetData[16].answerNo,
      reSetData[16][key],
    )
  }
  if (componentProps.pageNo === '17') {
    setCardsUpdateLog(
      'card5',
      userId,
      reSetData,
      data,
      reSetData[17].answerNo,
      reSetData[17][key],
    )
  }
  if (componentProps.pageNo === '18') {
    setCardsUpdateLog(
      'card6',
      userId,
      reSetData,
      data,
      reSetData[18].answerNo,
      reSetData[18][key],
    )
  }
  if (componentProps.pageNo === '20') {
    setCardsUpdateLog(
      'card7',
      userId,
      reSetData,
      data,
      reSetData[20].answerNo,
      reSetData[20][key],
    )
  }
  if (componentProps.pageNo === '7') {
    setCardsUpdateLog(
      'card8',
      userId,
      reSetData,
      data,
      reSetData[7].answerNo,
      reSetData[7][key],
    )
  }
  if (componentProps.pageNo === '11') {
    setCardsUpdateLog(
      'card9',
      userId,
      reSetData,
      data,
      reSetData[11].answerNo,
      reSetData[11][key],
    )
  }
  if (componentProps.pageNo === '21') {
    setCardsUpdateLog(
      'card10',
      userId,
      reSetData,
      data,
      reSetData[21].answerNo,
      reSetData[21][key],
    )
  }
  if (componentProps.pageNo === '22') {
    setCardsUpdateLog(
      'card11',
      userId,
      reSetData,
      data,
      reSetData[22].answerNo,
      reSetData[22][key],
    )
  }
  if (componentProps.pageNo === '27') {
    setCardsUpdateLog(
      'card12',
      userId,
      reSetData,
      data,
      reSetData[27].answerNo,
      reSetData[27][key],
    )
  }
  if (componentProps.pageNo === '31') {
    setCardsUpdateLog(
      'card13',
      userId,
      reSetData,
      data,
      reSetData[31].answerNo,
      reSetData[31][key],
    )
  }
  if (componentProps.pageNo === '29') {
    setCardsUpdateLog(
      'card14',
      userId,
      reSetData,
      data,
      reSetData[29].answerNo,
      reSetData[29][key],
    )
  }
  if (componentProps.pageNo === '30') {
    setCardsUpdateLog(
      'card15',
      userId,
      reSetData,
      data,
      reSetData[30].answerNo,
      reSetData[30][key],
    )
  }
let existingInvester = JSON.parse(localStorage?.getItem('Invester')!);
if(existingInvester){
  existingInvester = {...existingInvester, [key]:0}
  localStorage.setItem('Invester', JSON.stringify(existingInvester))
}
else{
  //@ts-ignore
  let invester:any = {[key]: 0}
  localStorage.setItem('Invester', JSON.stringify(invester))
}


let existingBespaar = JSON.parse(localStorage?.getItem('Bespaar')!);
if(existingBespaar){
  existingBespaar = {...existingBespaar, [key]:0}
  localStorage.setItem('Bespaar', JSON.stringify(existingBespaar))
}
else{
  //@ts-ignore
  let bespaar:any = {[key]: 0}
  localStorage.setItem('Bespaar', JSON.stringify(bespaar))
}


let existingPremies = JSON.parse(localStorage?.getItem('Premies')!);
if(existingPremies){
  existingPremies = {...existingPremies, [key]:0}
  localStorage.setItem('Premies', JSON.stringify(existingPremies))
}
else{
  //@ts-ignore
  let premies:any = {[key]: 0}
  localStorage.setItem('Premies', JSON.stringify(premies))
}




let existingCo2 = JSON.parse(localStorage?.getItem('Co2')!);
if(existingCo2){
  existingCo2 = {...existingCo2, [key]:0}
  localStorage.setItem('Co2', JSON.stringify(existingCo2))
}
else{
  //@ts-ignore
  let Co2:any = {[key]: 0}
  localStorage.setItem('Co2', JSON.stringify(Co2))
}


let existingMinder = JSON.parse(localStorage?.getItem('MinderEnergie')!);
if(existingMinder){
  existingMinder = {...existingMinder, [key]:0}
  localStorage.setItem('MinderEnergie', JSON.stringify(existingMinder))
}
else{
  //@ts-ignore
  let minderEnergie:any = {[key]: 0}
  localStorage.setItem('MinderEnergie', JSON.stringify(minderEnergie))
}


let existingElec = JSON.parse(localStorage?.getItem('PagesEnergyElec')!);
if(existingElec){
  existingElec = {...existingElec, [key]:0}
  localStorage.setItem('PagesEnergyElec', JSON.stringify(existingElec))
}
else{
  //@ts-ignore
  let pagesEnergyElec:any = {[key]: 0}
  localStorage.setItem('PagesEnergyElec', JSON.stringify(pagesEnergyElec))
}


let existingGas = JSON.parse(localStorage?.getItem('PagesEnergyGas')!);
if(existingGas){
  existingGas = {...existingGas, [key]:0}
  localStorage.setItem('PagesEnergyGas', JSON.stringify(existingGas))
}
else{
  //@ts-ignore
  let pagesEnergyGas:any = {[key]: 0}
  localStorage.setItem('PagesEnergyGas', JSON.stringify(pagesEnergyGas))
}


let existingVerkoop = JSON.parse(localStorage?.getItem('VerkoopElec')!);
if(existingVerkoop){
  existingVerkoop = {...existingVerkoop, [key]:0}
  localStorage.setItem('VerkoopElec', JSON.stringify(existingVerkoop))
}
else{
  //@ts-ignore
  let verkoopElec:any = {[key]: 0}
  localStorage.setItem('VerkoopElec', JSON.stringify(verkoopElec))
}

  sendConsumptions(userId, localImprovements, reSetData)
  localStorage.setItem('localImprovements', JSON.stringify(reSetData));

  const improvedCard  = localStorage.getItem('improvedCard');

  if(improvedCard){
    const improved = JSON.parse(improvedCard);
    delete improved[pageNo];
    localStorage.setItem('improvedCard', JSON.stringify(improved));

  }
  if(renderUpdate === true){
    setRenderUpdate(false);
  }
  else{
    setRenderUpdate(true);
  }
}


export  function getLoan(value : any) {
  try {
    // const paramsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/parameters`)

    const paramsResponse = JSON.parse(localStorage.getItem('parameters')!)

    const parameters = paramsResponse|| []
    const effectiveAnualIntrest =
      parameters.find((param : any) => param?.name === 'Jaarlijkse rentevoet lening')
        ?.value || 0
    const jearsForLoan =
      parameters.find((param : any) => param?.name === 'Looptijd lening')?.value || 0

    // Naming convention according to ticket number #119
    if(effectiveAnualIntrest > 0){
    const X = effectiveAnualIntrest / 100 
    const Y = X / 12 
    const Z = jearsForLoan * 12
    const A = 1 + Y
    const B = A ** -Z
    const C = 1 - B
    const D = Y / C 
    return D * value
    }else{
      const Z = jearsForLoan * 12
      return value / Z
    }

  } catch {
    return 0
  }
}
