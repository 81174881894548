
import { getVerwarmingTableData,getAdviceKey, getAdviceKeyXL} from './reultHelpers'
import axios from 'axios';
export const SET_INVESTEER = 'SET_INVESTEER'
export const SET_ENERGIELENING = 'SET_ENERGIELENING'
export const SET_PREMIES = 'SET_PREMIES'
export const SET_MINDER_ENERGIE = 'SET_MINDER_ENERGIE'
export const SET_ELECTRICITY = 'SET_ELECTRICITY'
export const SET_GAS = 'SET_GAS'
export const SET_VERKOOP_ELEC = 'SET_VERKOOP_ELEC'
export const SET_CO2 = 'SET_CO2'
export const SET_BESPAAR_CO2 = 'SET_BESPAAR_CO2'
export const SET_BESPAAR = 'SET_BESPAAR'
export const SET_HEAT_LOSS_DATA = 'SET_HEAT_LOSS_DATA'
export const SET_AREA_DATA = 'SET_AREA_DATA'
export const SET_ACTUAL_EPC_LABEL = 'SET_ACTUAL_EPC_LABEL'

export function setHeatLossData(
    buildingData = [],
    isFirstTime:boolean,
    heatLossDataUpdate = false,
    setHeatLossDataUpdate = (heatLossDataUpdate: boolean) => {},
    // settHeatLossData:any
  ) {
    let localState = localStorage.getItem('localState');
    let pages = JSON.parse(`${localState}`)
    // return (dispatch:any) => {
      const buildingType = getBuildingType(pages?.[1] && parseInt(pages?.[1].answerNo))
      const area = getArea(
        pages?.[3] && pages?.[3].answerNo,
        pages?.[1] && pages?.[1].answerNo,
        pages?.[3] && pages?.[3].oppervlakte_input,
        buildingData && buildingData[0],
      )
      const { page6, page7 } = getPage6And7(pages)
      const heating = getHeating(page6 && page6.answerNo, page7 && page7.answerNo)
      const heatingWarmWater = getHeatingWarmWater(
        pages?.[11] && pages?.[11].answerNo,
        page6 && page6.answerNo,
        page7 && page7.answerNo,
      )
      const typeOfHeating = getTypeOfHeating(
        page6 && page6.answerNo,
        page7 && page7.answerNo,
      )
  
      const ventilation = getVentilation(pages?.[21] && pages?.[21].answerNo)
      const slopingRoofIsolation = getSlopingRoofIsolation(
        pages?.[12] && pages?.[12].answerNo,
      )
      const flatRoofIsolation = getFlatRoofIsolation(
        pages?.[13] && pages?.[13].answerNo,
        slopingRoofIsolation,
      )
      const roofType = getRoofType(pages?.[13] && pages?.[13].answerNo)
      const florType = getFlorType(pages?.[20] && pages?.[20].answerNo)
  
      const windowType = getWindowType(
        (pages?.[15] && pages?.[15].answerNo) ||
          (pages?.[16] && pages?.[16].answerNo),
      )
  
      const isolationGround = getIsolationGround(
        pages?.[20] && pages?.[20].answerNo,
      )
      const isolationBasement = getIsolationBasement(
        pages?.[20] && pages?.[20].answerNo,
      )
  
      const wallIsolationAchter = getWallIsolation(
        pages?.[18] && pages?.[18].answerNo,
      )
      const wallIsolationVoor = getWallIsolation(
        pages?.[17] && pages?.[17].answerNo,
      )
      const wallIsolation =
        wallIsolationAchter == 'matig' || wallIsolationVoor == 'matig'
          ? 'matig'
          : wallIsolationAchter == 'nietZonderSpouw' ||
            wallIsolationVoor == 'nietZonderSpouw'
          ? 'nietZonderSpouw'
          : 'licht'
  
      const request = {
        bouwjaarVolgensEPC: 2015,
        pvAanwezig: pages?.[22] && pages?.[22].answerNo == 0,
        typeDak: roofType,
        typeGeometrie: area,
        typeGevelIsolatie: wallIsolation,
        typeHellendDakIsolatie: slopingRoofIsolation,
        typePlatDakIsolatie: flatRoofIsolation,
        typeVenster: windowType,
        typeVentilatie: ventilation,
        typeVerwarming: typeOfHeating,
        typeVloer: florType,
        typeVloerBovenKelderIsolatie: isolationBasement,
        typeVloerOpVolleGrondIsolatie: isolationGround,
        typeWoning: buildingType,
        verwarmSanitairWarmWaterMet: heatingWarmWater,
        verwarmingEnergiedrager: heating,
        zonneboilerAanwezig: pages?.[11] && pages?.[11].answerNo == 1,
      }
      fetch(
        `https://energie-dev.api.vlaanderen.be/energie-api/v1/berekenIndicatief`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-apikey': 'gkRYrwOouhFgYzhmt9Ov5JBGkZRqkZhJ',
          },
          body: JSON.stringify(request), // body data type must match "Content-Type" header
        },
      )
        .then((response) => response.json())
        .then((data) => {
          // dispatch({ type: SET_HEAT_LOSS_DATA, payload: data })
          let stringData = JSON.stringify(data);
          let stringData2 = data;
          let actual = data.label;
          localStorage.setItem('SET_HEAT_LOSS_DATA',stringData)
          setHeatLossDataUpdate(!heatLossDataUpdate)
          // localStorage.setItem('SET_ACTUAL_EPC_LABEL',actual )
          if (isFirstTime) {
            // let stringData = JSON.stringify(data.label)
            localStorage.setItem('SET_ACTUAL_EPC_LABEL',actual )
            // dispatch({ type: SET_ACTUAL_EPC_LABEL, payload: data.label })
          }
          // let obj = {
          //   SET_HEAT_LOSS_DATA:stringData2,
          //   SET_ACTUAL_EPC_LABEL:actual
          // }
          // console.log(obj)
          // return obj
        //   sessionPutCall(userId, {
        //     epc_score: data.primairEnergieverbruikPerM2,
        //   })
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    // }
  }
  export function getPage6And7(pages:any) {
    const { 6: page6, 7: page7 } = pages
  
    if (!page7?.fromImprovement) {
      return {
        page6,
        page7,
      }
    }
  
    return getAnswer6And7(page7)
  }
  function getAnswer6And7(page7:any) {
    if (page7.answerNo > 13) {
      return {
        page6: {
          verwarming: 'Een andere bron',
          answerNo: 3,
        },
        page7: {
          ...page7,
          answerNo: page7.answerNo - 14,
        },
      }
    }
    if (page7.answerNo > 9) {
      return {
        page6: {
          verwarming: 'Stookolie',
          answerNo: 2,
        },
        page7: {
          ...page7,
          answerNo: page7.answerNo - 10,
        },
      }
    }
    if (page7.answerNo > 3) {
      return {
        page6: {
          verwarming: 'Elektriciteit',
          answerNo: 1,
        },
        page7: {
          ...page7,
          answerNo: page7.answerNo - 4,
        },
      }
    }
    return {
      page6: {
        verwarming: 'Gas',
        answerNo: 0,
      },
      page7: {
        ...page7,
        answerNo: page7.answerNo,
      },
    }
  }
  export const getBuildingType = (answer:number) => {
    // if(answer == "Open bebouwing"){
    //   answer = 0
    // }

    
    switch (answer) {
      case 0:
        return 'open'
      case 1:
        return 'halfopen'
      case 2:
        return 'gesloten'
      // default:
      //   return 'AppartementIngesloten'
      default:
        return 'appartment'
    }
  }
  
  export const getArea = (answer:any, answer1:any, input:any, buildingData:any) => {
    if (answer == 0 && input == 0) {
      return 'groot'
    }
    if (answer == 1 && input == 0) {
      return 'gemiddeld'
    }
    if (answer == 2 && input == 0) {
      return 'klein'
    }
    if (
      (answer1 == 0 && input >= buildingData.groot_open) ||
      (answer1 == 1 && input >= buildingData.groot_halfopen) ||
      (answer1 == 2 && input >= buildingData.groot_gesloten)
    ) {
      return 'groot'
    }
    if (
      (answer1 == 0 &&
        input < buildingData.groot_open &&
        input > buildingData.klein_open) ||
      (answer1 == 1 &&
        input < buildingData.groot_halfopen &&
        input > buildingData.klein_halfopen) ||
      (answer1 == 2 &&
        input < buildingData.groot_gesloten &&
        input > buildingData.klein_gesloten)
    ) {
      return 'gemiddeld'
    }
    if (
      (answer1 == 0 && input <= buildingData.klein_open) ||
      (answer1 == 1 && input <= buildingData.klein_halfopen) ||
      (answer1 == 2 && input <= buildingData.klein_gesloten)
    ) {
      return 'klein'
    }
    return 'groot'
  }
  
  export const getHeating = (answer:any, answerNext:any) => {
    switch (answer) {
      case 0:
        return 'Gas'
      case 1:
        return 'Elektriciteit'
      case 2:
        return 'Stookolie'
      default:
        switch (answerNext) {
          case 0:
            return 'Warmtenet'
          case 1:
            return 'Pellets'
          default:
            return 'Hout'
        }
    }
  }
  
  export const getHeatingWarmWater = (answer11:any, answer6:any, answer7:any) => {
    switch (answer11) {
      case 0:
      case 1:
        switch (answer6) {
          case 0:
            return 'gas'
          case 1:
            switch (answer7) {
              case 0:
              case 1:
                return 'elektrische boiler'
              default:
                return 'warmtepompboiler'
            }
          case 2:
            return 'Stookolie'
          default:
            return 'elektrische boiler'
        }
      case 2:
        return 'warmtepompboiler'
      case 3:
        return 'elektrische boiler'
      default:
        return 'gas'
    }
  }
  
  export const getVentilation = (answer:any) => {
    switch (answer) {
      case 0:
        return 'geen'
      case 1:
        return 'A'
      case 2:
        return 'C'
      case 3:
        return 'Cwtw'
      default:
        return 'Dwtw'
    }
  }
  
  export const getSlopingRoofIsolation = (answer:any) => {
    switch (answer) {
      case 0:
      case 4:
        return 'niet'
      case 1:
        return 'licht'
      case 2:
        return 'matig'
      default:
        return 'sterk'
    }
  }
  export const getFlatRoofIsolation = (answer:any, page12Value:any) => {
    switch (answer) {
      case 0:
        return 'niet'
      case 1:
        return 'licht'
      case 2:
        return 'matig'
      case 3:
        return 'sterk'
      default:
        return page12Value
    }
  }
  
  export const getTypeOfHeating = (answerSix:any, answerSeven:any) => {
    switch (answerSix) {
      case 0:
      case 2:
        switch (answerSeven) {
          case 0:
            return 'Kachel'
          case 1:
            return 'Niet-condenserende ketel'
          case 2:
            return 'Condenserende ketel'
          default:
            return 'Condenserende ketel'
        }
      case 1:
        switch (answerSeven) {
          case 0:
            return 'Elektrische weerstandsverwarming'
          case 1:
          case 3:
            return 'Elektrische warmtepomp lucht-water'
          case 2:
            return 'Elektrische warmtepomp lucht-lucht'
          default:
            return 'Elektrische warmtepomp bodem-water'
        }
      default:
        switch (answerSeven) {
          case 0:
            return null
          default:
            return 'Kachel'
        }
    }
  }
  
  export const getRoofType = (answer:any) => {
    switch (answer) {
      case 0:
      case 1:
      case 2:
      case 3:
        return 'gemengd'
      default:
        return 'Dak_hellend'
    }
  }
  
  export const getFlorType = (answer:any) => {
    switch (answer) {
      case 0:
      case 1:
        return 'volleGrond'
      default:
        return 'gemengd'
    }
  }
  
  export const getWindowType = (answer:any) => {
    switch (answer) {
      case 0:
        return 'enkel'
      case 1:
        return 'dubbel'
      case 2:
        return 'hr'
      default:
        return 'driedubbel'
    }
  }
  
  export const getIsolationGround = (answer:any) => {
    switch (answer) {
      case 0:
        return 'sterk'
      case 1:
        return 'niet'
      default:
        return 'matig'
    }
  }
  
  export const getIsolationBasement = (answer:any) => {
    switch (answer) {
      case 0:
        return 'sterk'
      case 1:
        return 'niet'
      default:
        return 'matig'
    }
  }
  
  export const getWallIsolation = (answer:any) => {
    switch (answer) {
      case 0:
        return 'matig'
      case 1:
        return 'nietZonderSpouw'
      default:
        return 'licht'
    }
  }

  export function getDimensions(
    areaSet:any,
    opperKey:any,
    oppervlakteId:any,
    modalTitle:any,
    page13:any,
    setState:any,
    resultSetState:any,
    pageIndex:any,
    buildingData:any
  ) {
    setState((st:any) => ({
      ...st,
      loading: true,
    }))
  if(buildingData?.length == 0){
return '';
  }
    // axios
    //   .get(`${process.env.REACT_APP_BACKEND_URL}/dimensions`)
    //   .then((response) => {
   
        const data = buildingData?.filter((d:any) => Number(d.id) == oppervlakteId)
        const zonneweringOne = buildingData?.filter(
          (d:any) => Number(d.id) == oppervlakteId.key1,
        )
        const zonneweringTwo = buildingData?.filter(
          (d:any) => Number(d.id) == oppervlakteId.key2,
        )
        const groendakOne = buildingData?.filter(
          (d:any) => Number(d.id) == oppervlakteId.key1,
        )
        const groendakTwo = buildingData?.filter(
          (d:any) => Number(d.id) == oppervlakteId.key2,
        )
        const hoofdak = buildingData?.filter(
          (d:any) => Number(d.id) == oppervlakteId.key3,
        )
          // console.log("==>",pageIndex)
          // regenput
          if(pageIndex == 29){
            const hoofdgebouw = (buildingData?.filter((d:any) => Number(d.id) == oppervlakteId.key1))[0][opperKey]
            const bijgebouw = buildingData?.filter((d:any) => Number(d.id) == oppervlakteId.key2)[0][opperKey]
            const total = hoofdgebouw + bijgebouw;
            areaSet(Math.round(total))
            setState((st:any) => ({
              ...st,
              loading: false,
              areaApiValue: Math.round(total),
            }))
          }else{
            if (
              modalTitle !== 'Zonnepanelen' &&
              modalTitle !== 'Zonnewering' &&
              modalTitle !== 'Groendak' &&
              modalTitle !== 'Hoofddak'
            ) {
              // const localArea = localStorage.getItem('area')
              // if (localArea != Math.round(data[0][opperKey])) {
              // localStorage.setItem('area', Math.round(data[0][opperKey]))
              areaSet(Math.round(data?.[0][opperKey?opperKey:0]))
              setState((st:any) => ({
                ...st,
                loading: false,
                areaApiValue: Math.round(data?.[0][opperKey?opperKey:0]),
              }))
              // }
            }
            if (modalTitle == 'Zonnewering') {
              const data =
                Math.round(zonneweringOne[0][opperKey]) +
                Math.round(zonneweringTwo[0][opperKey])
              areaSet(data)
              setState((st:any) => ({
                ...st,
                loading: false,
                areaApiValue: data,
              }))
            }
            if (modalTitle == 'Groendak') {
              const data =
                Math.round(groendakOne[0][opperKey]) +
                Math.round(groendakTwo[0][opperKey])
              areaSet(data)
              setState((st:any) => ({
                ...st,
                loading: false,
                areaApiValue: data,
              }))
            }
            if (modalTitle == 'Hoofddak') {
              if (page13 == 4) {
                const data =
                  Math.round(groendakOne[0][opperKey]) +
                  Math.round(groendakTwo[0][opperKey])
                areaSet(data)
                setState((st:any) => ({
                  ...st,
                  loading: false,
                  areaApiValue: data,
                }))
              } else {
                areaSet(Math.round(hoofdak[0][opperKey]))
                setState((st:any) => ({
                  ...st,
                  loading: false,
                  areaApiValue: Math.round(hoofdak[0][opperKey]),
                }))
              }
            }
          }
        resultSetState((st:any) => ({
          ...st,
          isAreaUpdated: {
            ...st.isAreaUpdated,
            [modalTitle]: true,
          },
        }))
      // })
      // .catch((err) => {
      //   console.log(err)
      //   setState((st:any) => ({
      //     ...st,
      //     loading: false,
      //   }))
      // })
  }
  export function getImprovements(setState:any, modalTitle:any, selectedOptText:any, area:any,pageIndex:any,vergronenArea:any) {
    // console.log(modalTitle,"modalTitle");
    // console.log(selectedOptText,"selectedOptText");
    // console.log(area,"area");
    // console.log(pageIndex,"pageIndex");
    // console.log(vergronenArea,"vergronenArea");
    let localState = localStorage.getItem('localState');
    let localImprovements = JSON.parse(`${localState}`)
    
    setState((st:any) => ({
      ...st,
      loading: true,
    }))
  
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/improvements`)
      .then((response) => {
        let tableData:any = []
        let improvementPeram = ''
  
        tableData = response.data.filter(
          (table:any) => table.improvementName == modalTitle,
        )
        if(pageIndex == 30){
        // Vergroenen
        let investmentCost = tableData[0].improvementOptions[0].investmentCost
        // console.log(vergronenArea,'vergronenArea')
        setState((st:any) => ({
          ...st,
          investCost: vergronenArea.reduce((partialSum:any, a:any) => partialSum + a, 0) * investmentCost,
          loading: false,
          }))
        }
        else if(pageIndex == 29){
          //Regentput
          if(localImprovements[1].answerNo == 0 || localImprovements[1].answerNo == 1){
            tableData = tableData[0].improvementOptions.filter(
              (prem:any) => prem.selectedOption == "Regenput voor (half)open bebouwing",
            )
            setState((st:any) => ({
              ...st,
              investCost: tableData[0].investmentCost,
              loading: false,
            }))
          }else{
            tableData = tableData[0].improvementOptions.filter(
              (prem:any) => prem.selectedOption == "Regenput voor gesloten bebouwing",
            )
            setState((st:any) => ({
              ...st,
              investCost: tableData[0].investmentCost,
              loading: false,
            }))
          }
  
  
        }else{
  
          if(pageIndex== 6){modalTitle = 'Verwarming: '+ localImprovements[6].verwarming}

          if (
            modalTitle == 'Verwarming: gas' ||
            modalTitle == 'Verwarming: elektriciteit' ||
            modalTitle == 'Verwarming: stookolie' ||
            modalTitle == 'Verwarming: eenanderebron'
          ) {
            tableData = getVerwarmingTableData(
              response.data,
              modalTitle,
              selectedOptText,
            )
          } else {
    
            tableData = response.data.filter(
              (table:any) => table.improvementName == modalTitle,
            )
            if (modalTitle == 'Zonnepanelen') {
              const investCost =
                tableData?.[0]?.improvementOptions?.[0]?.investmentCost
    
              setState((st:any) => ({
                ...st,
                investCost: investCost * area,
                loading: false,
              }))
            }
          }  
          if (selectedOptText !== undefined) {
            if (tableData.length > 0) {
              improvementPeram = tableData[0].improvementPeram
              tableData = tableData[0].improvementOptions.filter(
                (prem:any) => prem.selectedOption == selectedOptText,
              )
            }
            if (tableData.length > 0) {
              if (improvementPeram == '€/m²') {
                setState((st:any) => ({
                  ...st,
                  investCost: tableData[0].investmentCost * area,
                  loading: false,
                }))
              } else {
                setState((st:any) => ({
                  ...st,
                  investCost: tableData[0].investmentCost,
                  loading: false,
                }))
              }
            } else {
              setState((st:any) => ({
                ...st,
                investCost: 0,
                loading: false,
              }))
            }
          }
  
  
  
        }
        
      })
      .catch((err) => {
        console.log(err)
        setState((st:any) => ({
          ...st,
          loading: false,
        }))
      })
  }


  // const initialState = {
  //   investeer: {},
  //   area: {},
  //   energielening: 0,
  //   premies: {},
  //   minderEnergie: {},
  //   electricity: {},
  //   gas: {},
  //   verkoopElectricity: {},
  //   co2: {},
  //   bespaar: {},
  //   bespaarCo2: 0,
  //   heatLossData: {},
  // }
  // export default function (state = initialState, action:any) {
  //   switch (action.type) {
  //     case SET_INVESTEER:
  //       return { ...state, investeer: action.payload }
  //     case SET_BESPAAR:
  //       return { ...state, bespaar: action.payload }
  //     case SET_BESPAAR_CO2:
  //       return { ...state, bespaarCo2: action.payload }
  //     case SET_ENERGIELENING:
  //       return { ...state, energielening: action.payload }
  //     case SET_MINDER_ENERGIE:
  //       return { ...state, minderEnergie: action.payload }
  //     case SET_ELECTRICITY:
  //       return { ...state, electricity: action.payload }
  //     case SET_GAS:
  //       return { ...state, gas: action.payload }
  //     case SET_VERKOOP_ELEC:
  //       return { ...state, verkoopElectricity: action.payload }
  //     case SET_CO2:
  //       return { ...state, co2: action.payload }
  //     case SET_PREMIES:
  //       return { ...state, premies: action.payload }
  //     case SET_HEAT_LOSS_DATA:
  //       return { ...state, heatLossData: action.payload }
  //     case SET_AREA_DATA:
  //       return { ...state, area: action.payload }
  //     case SET_ACTUAL_EPC_LABEL:
  //       return { ...state, actualEpcLabel: action.payload }
  //     default:
  //       return state
  //   }
  // }
  
  export function getGroups(
    setState : any,
    premImprovment: any,
    oppervlakte: any,
    investCost: any,
    page4: any,
    pageIndex: any,
    vergronenArea: any,
    parameters:any
  ) {
    setState((st : any) => ({
      ...st,
      loading: true,
    }))
    // 'premImprovment' maybe look like this: "Ventilatie - Vraaggestuurd mechanisch (C)"
    const data = premImprovment?.split("-").map((value: string) => value.trim())
    const renovation = data?.length ? data[0] : null
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}groups/improvements/`, { data: premImprovment })
      .then(async (response) => {
  
  
        const { data } = response
        const premiumsTotal = data
       
        // const paramsResponse = JSON.parse(localStorage.getItem('parameters'))
  
        // const parameters = paramsResponse?.data?.data?.[0] || []
  
        const solarParam = parameters.find((param : any) => param?.id == '34')?.value || 0;
       
        const getPercentageValue = (isPercent: any, value: any, totalValue: any, xValue: any) => {
          if (isPercent) {
            const percentValue = (value / 100) * totalValue
            if (percentValue < xValue) {
              return percentValue
            }
  
            return xValue
          }
  
          return value
        }
        const premiesData = []
  
        await premiumsTotal.map((item: any) => {
          // const filteredItem = item
          let calculateFormula:any
  
          if (item.formula == 'X * oppervlakte') {
            calculateFormula = Number(item.x * oppervlakte)
            if (calculateFormula > Number(item.maximum)) {
              premiesData.push({
                value: item.maximum,
                percentage: item.percentage,
                xValue: item.x,
                formula: item.formula,
                improvement: item.improvement,
              })
            }
          } else if (item.formula == 'X%') {
            calculateFormula = Number((item.x / 100) * investCost)
            if (calculateFormula > Number(item.maximum)) {
              premiesData.push({
                value: item.maximum,
                percentage: item.percentage,
                xValue: item.x,
                formula: item.formula,
                improvement: item.improvement,
              })
            }
          } else if (item.formula == 'X * kWp') {
            calculateFormula = oppervlakte * Number(item.x) * solarParam
            if (calculateFormula > Number(item.maximum)) {
              premiesData.push({
                value: item.maximum,
                percentage: item.percentage,
                xValue: item.x,
                formula: item.formula,
                improvement: item.improvement,
              })
            }
          } else {
            premiesData.push({
              value: item.maximum,
              percentage: item.percentage,
              xValue: item.x,
              formula: item.formula,
              improvement: item.improvement,
            })
          }
        })
  
        let summ: any = []
  
        if(pageIndex == 30){
          let premieVoortuin = parameters.filter((data: any) => data.id == 47)[0].value * vergronenArea[0] ,
          premieAchtertuin = parameters.filter((data: any) => data.id == 48)[0].value * vergronenArea[1],
          premieOprit = parameters.filter((data: any) => data.id == 49)[0].value * vergronenArea[2];
          let totalArea =  vergronenArea[0] +  vergronenArea[1] +  vergronenArea[2]
          setState((st: any) => ({ ...st, premAmount: totalArea >= 10 ? Number(premieVoortuin+premieAchtertuin+premieOprit) : 0, loading: false }))
  
        }else if (data.length) {
          await Promise.all(
  
  
            data.map(async (t: any) => {
              const max = t.percentage
                ? (investCost / 100) * t.maximum
                : t.maximum
                ? t.maximum
                : 1000000000000
              if (t.formula == 'X') {
                if (max && t.x > max) {
                  summ.push({
                    value: Number(max),
                    type: t.type,
                    groupsId: t.groupsId,
                  })
                } else {
                  summ.push({ value: t.x, type: t.type, groupsId: t.groupsId })
                }
              }
              if (t.formula == 'X * oppervlakte') {
                const calculateFormula = Number(t.x * oppervlakte)
  
                if (max && calculateFormula > max) {
                  summ.push({
                    value: Number(max),
                    type: t.type,
                    groupsId: t.groupsId,
                  })
                } else {
                  summ.push({
                    value: calculateFormula,
                    type: t.type,
                    groupsId: t.groupsId,
                  })
                }
              }
              if (t.formula == 'X%') {
                const calculateFormula = Number((t.x / 100) * investCost)
  
                if (max && calculateFormula > max) {
                  summ.push({
                    value: Number(max),
                    type: t.type,
                    groupsId: t.groupsId,
                  })
                } else {
                  summ.push({
                    value: calculateFormula,
                    type: t.type,
                    groupsId: t.groupsId,
                  })
                }
              }
              if (t.formula == 'X * kWp') {
                const calculateFormula = oppervlakte * Number(t.x) * solarParam
  
                if (max && calculateFormula > max) {
                  summ.push({
                    value: Number(max),
                    type: t.type,
                    groupsId: t.groupsId,
                  })
                } else {
                  summ.push({
                    value: calculateFormula,
                    type: t.type,
                    groupsId: t.groupsId,
                  })
                }
              }
            }),
          )
  
          if (page4 == 0) {
            summ = summ.filter((a: any) => {
              return a.type == 'Bewoner'
            }, 0)
          } else {
            summ = summ.filter((a: any) => {
              return a.type == 'Verhuurder'
            }, 0)
          }
  
          const p : any= []
          await summ.map((d: any, index: any) => {
            if (index > 0) {
              if (Number(p[index - 1].groupsId) == Number(d.groupsId)) {
                if (p[index - 1].value > d.value) {
                  p.pop()
                  p.push(d)
                }
              } else {
                p.push(d)
              }
            } else {
              p.push(d)
            }
          })
  
          summ = p.reduce((a: any, b: any) => {
            return a + b.value
          }, 0)
          setState((st: any) => ({ ...st, premAmount: summ, loading: false }))
        } else {
          setState((st: any) => ({ ...st, premAmount: 0, loading: false }))
        }
  
      })
      .catch((err) => {
        console.log(err)
        setState((st: any) => ({
          ...st,
          loading: false,
        }))
      })
  }

  async function getSolarArea(itemKey:any, area:any, parameters:any) {
    if (itemKey == 'zonnepanelen') {
      // const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/parameters`)
      // const parameters = JSON.parse(localStorage.getItem('parameters'))
      const zonnepanelenSquareMeter =
        parameters?.data?.data[0].find((item:any) => item.id == '30') || 0
      const unit = zonnepanelenSquareMeter.value
      return Math.floor(area / unit)
    }
    return 0
  }

    // Result page calculations helper
export async function getCalculationsBar(
  pages:any,
  improvedPages:any,
  setState:any,

) {
  const pagesPayload = await getCalculationPayload(pages)
  const improvedPagesPayload = await getCalculationPayload(
    improvedPages,
    true,
    0,
    ['gas', 'elektriciteit', 'stookolie', 'ean_andere_bron'].includes(improvedPages[6].verwarming),
  )



    localStorage.setItem('currentPayload', JSON.stringify(pagesPayload))
    localStorage.setItem( 'improvedPayload',JSON.stringify(improvedPagesPayload) )

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/calculation?getBarCalculations`, {
        current: pagesPayload,
        improvements: improvedPagesPayload,
      })
      .then(async (response) => {
        // localStorage.setItem('resultBar', JSON.stringify(response))
        // console.log("response",response)
        // console.log("response.data",response.data)
        // console.log("response.data.data",response.data.data)
        setState(response.data)


      })
      .catch((exception) => {
        console.log(exception)

      })
  
}
  
  // Result page calculations helper
export async function getResultCalculations(
  pages:any,
  improvedPages:any,
  setState:any,
  type:any,
  itemKey:any,
  area:any,
  userId:string,
  parametersData:any
) {
  setState((st:any) => ({
    ...st,
    loading: true,
  }))
  // console.log("==> Alain ==>",type)
  // console.log("==> Alain ==>",area)
  const solarArea = await getSolarArea(itemKey, area,parametersData)
  const pagesPayload = await getCalculationPayload(pages)
  const improvedPagesPayload = await getCalculationPayload(
    improvedPages,
    true,
    solarArea,
    ['gas', 'elektriciteit', 'stookolie', 'ean_andere_bron'].includes(itemKey),
  )

  // console.log("===>",itemKey,['gas', 'elektriciteit', 'stookolie', 'ean_andere_bron'].includes(pages[6].verwarming.toLowerCase()))
  // console.log("===>",improvedPages)
  // console.log("===>",improvedPagesPayload)
  // const improvedPagesPayloadAll = await getCalculationPayload(
  //   improvedPagesAll,
  //   true,
  //   solarArea,
  //   ['gas', 'elektriciteit', 'stookolie', 'ean_andere_bron'].includes(itemKey),
  //   true,
  // )
  const storedCurrentPayload = localStorage.getItem('currentPayload')
  const storeImprovedPayload = localStorage.getItem('improvedPayload')




  const storedResult = localStorage.getItem('result')!
  const checkCurrentPayload =
    JSON.stringify(pagesPayload) == storedCurrentPayload
  const checkImprovedPayload =
    JSON.stringify(improvedPagesPayload) == storeImprovedPayload;
  // const parameters = JSON.parse(localStorage.getItem('parameters'))
  const parameters = parametersData;
  const unitRateGas = parseRate(parameters, 0)
  const unitRateElec = parseRate(parameters, 1)
  const unitRateInjectie = parseRate(parameters, 2)

  const page25 = pages?.[25] && pages?.[25].answerNo
  let verkoopElec = 0
  const verbruikZonnepaneelKwh =
    parameters?.find((item:any) => item.id == '35') || 0
  const zelfconsumptieZonnepanelen =
    parameters?.find((item:any) => item.id == '33') || 0
    // console.log(verbruikZonnepaneelKwh,'verbruikZonnepaneelKwh')
    // console.log(zelfconsumptieZonnepanelen,'zelfconsumptieZonnepanelen')
  await setState((st:any) => ({
    ...st,
    calculations: {
      ...st.calculations,
      energyDifference: 0,
      energyRate: 0,
    },
    loading: false,
  }))
  // console.log("==>",checkCurrentPayload)
  // console.log("==>",checkImprovedPayload)
  // console.log("==>",storedResult)
  // console.log(checkCurrentPayload,'checkCurrentPayload')
  // console.log(checkImprovedPayload,'checkImprovedPayload')
  // console.log(storedResult,'storedResult')
  if (false) {
    const result = JSON.parse(storedResult)
    // console.log(result,'result');
    const pagesEnergyGas = Number(parseEnergy(result.data, 0))
    const pagesEnergyElec = Number(parseEnergy(result.data, 1))
    // let pages_Energy_Gas = {[itemKey]:pagesEnergyGas}
    // localStorage.setItem('PagesEnergyGas', JSON.stringify(pages_Energy_Gas));
   
    // let pages_Energy_Elec = {[itemKey]:pagesEnergyElec}
    // localStorage.setItem('pagesEnergyElec', JSON.stringify(pages_Energy_Elec));
  //   console.log(itemKey,'itemKey')
  //  console.log(pagesEnergyGas,'pagesEnergyGas');
  //  console.log(pagesEnergyElec,'pagesEnergyElec');
    const energyDifferenceBoth = Number(
      pagesEnergyGas + pagesEnergyElec,
    ).toFixed(2)
    let energyDifference:any = energyDifferenceBoth
    let energyRate:any = Number(
      Number(pagesEnergyGas * unitRateGas) +
        Number(pagesEnergyElec * unitRateElec),
    ).toFixed(2)

    // if (type == 'solar' && page25 == 0) {
    if (type == 'solar') {
      energyDifference =
        (Number(area) *
          Number(verbruikZonnepaneelKwh.value) *
          Number(zelfconsumptieZonnepanelen.value)) /
        100
      energyRate =
        (Number(area) *
          Number(verbruikZonnepaneelKwh.value) *
          Number(zelfconsumptieZonnepanelen.value) *
          Number(unitRateElec) +
          area *
            Number(verbruikZonnepaneelKwh.value) *
            (100 - Number(zelfconsumptieZonnepanelen.value)) *
            Number(unitRateInjectie)) /
        100
      verkoopElec =
        Number(area) *
        Number(verbruikZonnepaneelKwh.value) *
        ((100 - Number(zelfconsumptieZonnepanelen.value)) / 100)
    }
    // if (type == 'solar' && page25 == 1) {
    //   energyDifference = area * verbruikZonnepaneelKwh.value
    //   energyRate = area * verbruikZonnepaneelKwh.value * unitRateElec
    //   verkoopElec =
    //     area *
    //     verbruikZonnepaneelKwh.value *
    //     (100 - zelfconsumptieZonnepanelen.value)
    // }
    // console.log("getResultCalculations area",area)
    // console.log("getResultCalculations verbruikZonnepaneelKwh.value",verbruikZonnepaneelKwh.value)
    // console.log("getResultCalculations zelfconsumptieZonnepanelen.value",zelfconsumptieZonnepanelen.value)
    // console.log("getResultCalculations unitRateElec",unitRateElec)
    // console.log("getResultCalculations energyRate",energyRate)
    // console.log(energyDifference,'energyDifference');
    // console.log(energyRate,'energyRate');
    // console.log(pagesEnergyGas,'pagesEnergyGas');
    // console.log(pagesEnergyElec,'pagesEnergyElec');
    // console.log(verkoopElec,'verkoopElec');
    await setState((st:any) => ({
      ...st,
      calculations: {
        energyDifference,
        energyRate,
        pagesEnergyGas,
        pagesEnergyElec,
        verkoopElec,
      },
      loading: false,
    }))
    // console.log('\r\n------------ Modal Calculation - Local ------------\r\n')
    // console.log('unitRateGas', unitRateGas)
    // console.log('unitRateElec', unitRateElec)
    // console.log('unitRateInjectie', unitRateInjectie)
    // console.log('energyDifferenceBoth', energyDifferenceBoth)
    // console.log('pagesEnergyGas', pagesEnergyGas)
    // console.log('pagesEnergyElec', pagesEnergyElec)
    // console.log('energyDifference', energyDifference)
    // console.log('energyRate', energyRate)
    // console.log('-------------------------------------------\r\n')
  } else {
    setState((st:any) => ({
      ...st,
      loading: true,
    }))
    localStorage.setItem('currentPayload', JSON.stringify(pagesPayload))
    localStorage.setItem( 'improvedPayload',JSON.stringify(improvedPagesPayload) )

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/calculation?getResultCalculations`, {
        current: pagesPayload,
        improvements: improvedPagesPayload,
        userId,
      })
      .then(async (response) => {
        localStorage.setItem('result', JSON.stringify(response))
        const pagesEnergyGas = Number(parseEnergy(response.data, 0))
        const pagesEnergyElec = Number(parseEnergy(response.data, 1))
        // let pages_Energy_Gas = {[itemKey]:pagesEnergyGas}
        // localStorage.setItem('PagesEnergyGas', JSON.stringify(pages_Energy_Gas));
       
        // let pages_Energy_Elec = {[itemKey]:pagesEnergyElec}
        // localStorage.setItem('pagesEnergyElec', JSON.stringify(pages_Energy_Elec));
        const energyDifferenceBoth = Number(
          pagesEnergyGas + pagesEnergyElec,
        ).toFixed(2)
        console.log('pages', energyDifferenceBoth);
        let energyDifference:any = energyDifferenceBoth

        // console.log(pagesEnergyGas,'pagesEnergyGas')
        // console.log(unitRateGas,'unitRateGas')
        // console.log(pagesEnergyElec,'pagesEnergyElec')
        // console.log(unitRateElec,'unitRateElec')
        let energyRate:any = Number(
          Number(pagesEnergyGas * unitRateGas) +
            Number(pagesEnergyElec * unitRateElec),
        ).toFixed(2)

        // if (type == 'solar' && page25 == 0) {
        if (type == 'solar') {

          energyDifference =
            (area *
              verbruikZonnepaneelKwh.value *
              zelfconsumptieZonnepanelen.value) /
            100
            // console.log("solar energyDifference",energyDifference)
            energyRate =
            (area *
              verbruikZonnepaneelKwh.value *
              zelfconsumptieZonnepanelen.value *
              unitRateElec +
              area *
                verbruikZonnepaneelKwh.value *
                (100 - zelfconsumptieZonnepanelen.value) *
                unitRateInjectie) /
            100
            // console.log("solar energyRate",energyRate)

          verkoopElec =
            area *
            verbruikZonnepaneelKwh.value *
            ((100 - zelfconsumptieZonnepanelen.value) / 100)
            // console.log("solar verkoopElec",verkoopElec)

        }
        // if (type == 'solar' && page25 == 1) {
        //   energyDifference = area * verbruikZonnepaneelKwh.value
        //   energyRate = area * verbruikZonnepaneelKwh.value * unitRateElec
        //   verkoopElec =
        //     area *
        //     verbruikZonnepaneelKwh.value *
        //     (100 - zelfconsumptieZonnepanelen.value)
        // }

        // console.log("getResultCalculations area",area)
        // console.log("getResultCalculations verbruikZonnepaneelKwh.value",verbruikZonnepaneelKwh.value)
        // console.log("getResultCalculations unitRateElec",unitRateElec)
        // console.log(energyDifference,'energyDifference in else');
        // console.log(energyRate,'energyRate in else');
        // console.log(pagesEnergyGas,'pagesEnergyGas in else');
        // console.log(pagesEnergyGas,'pagesEnergyGas in else');
        // console.log(pagesEnergyElec,'pagesEnergyElec in else');
        // console.log(verkoopElec,'verkoopElec in else');
        console.log('pages', pagesEnergyElec, energyDifference);
        await setState((st:any) => ({
          ...st,
          calculations: {
            energyDifference,
            energyRate,
            pagesEnergyElec,
            pagesEnergyGas,
            verkoopElec,
          },
          loading: false,
        }))
        // console.log('\r\n------------ Modal Calculation - API ------------\r\n')
        // console.log('unitRateGas', unitRateGas)
        // console.log('unitRateElec', unitRateElec)
        // console.log('unitRateInjectie', unitRateInjectie)
        // console.log('energyDifferenceBoth', energyDifferenceBoth)
        // console.log('pagesEnergyGas', pagesEnergyGas)
        // console.log('pagesEnergyElec', pagesEnergyElec)
        // console.log('energyDifference', energyDifference)
        // console.log('energyRate', energyRate)
        // console.log('-------------------------------------------\r\n')
      })
      .catch((exception) => {
        console.log(exception)
        setState((st:any) => ({
          ...st,
          loading: false,
        }))
      })
  }
}
function getGasSelection(page7Answer:any) {
  switch (page7Answer) {
    case 0:
      return 1
    case 1:
      return 2
    case 2:
      return 3
    case 3:
      return 4

    default:
      return 1
  }
}

function getElecSelection(page7Answer:any) {
  switch (page7Answer) {
    case 0:
      return 5
    case 1:
      return 6
    case 2:
      return 7
    case 3:
      return 8
    case 4:
      return 9
    case 5:
      return 10

    default:
      return 1
  }
}

function getStookolieSelection(page7Answer:any) {
  switch (page7Answer) {
    case 0:
      return 11
    case 1:
      return 12
    case 2:
      return 13
    case 3:
      return 14

    default:
      return 1
  }
}

function getAndereelection(page7Answer:any) {
  switch (page7Answer) {
    case 0:
      return 15
    case 1:
      return 16
    case 2:
      return 17

    default:
      return 0
  }
}
export function getAnswer(pages:any, key:any) {
  return pages?.[key]?.answerNo
}
export function getCalculationOfPage7(pages:any) {
  let value = getAnswer(pages, 8);
  value = Number(value) + 1
  return value
}
export function getCalculationOfPage6(pages:any, isImprovement:any) {
  const page6Answer = Number(getAnswer(pages, 6))
  const page7Answer = Number(getAnswer(pages, 7))


  if (isImprovement) {
    return Number(page7Answer) + 1
  }

  if (page6Answer == 0) {
    return getGasSelection(page7Answer)
  }

  if (page6Answer == 1) {
    return getElecSelection(page7Answer)
  }

  if (page6Answer == 2) {
    return getStookolieSelection(page7Answer)
  }

  if (page6Answer == 3) {
    return getAndereelection(page7Answer)
  }

  return 1
}

export async function getCalculationOfPage3(answer:any, answer1:any, input:any) {
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/dimensions`)
  const resAnswer = getArea(answer, answer1, input, response.data[0])
  const arr = {
    groot: 1,
    gemiddeld: 2,
    klein: 3,
  }
  const result = arr[resAnswer]
  return result
}
export async function getCalculationPayload(
  pages:any,
  isImprovement:boolean = false,
  solarArea:number = 0,
  isVerwarming:boolean = false,
) {
  // console.log("pages, isVerwarming",pages, isVerwarming)
  // console.log("getCalculationPayload ===> ",pages)
  // console.log("pages[17]?.answerNo ===> ",Number(parseInt (pages[17]?.answerNo) + 1))
  if(pages){
    console.log('pages', pages);
    let energy_string:any = localStorage.getItem('energyValues');
    let enery_values = JSON.parse(energy_string);
    const page3Calculation = await getCalculationOfPage3(
      pages[3]?.answerNo,
      pages[1]?.answerNo,
      pages[3]?.oppervlakte_input,
    )
    return {
      1: parseInt (pages[1]?.answerNo) + 1,
      2: parseInt (pages[2]?.answerNo) + 1,
      3: page3Calculation,
      5: parseInt (pages[5]?.answerNo) + 1,
      6: parseInt (pages[7]?.answerNo) + 1,
      7: parseInt (getCalculationOfPage7(pages)) || 1,
      8: parseInt (pages[9]?.answerNo) + 1,
      9: parseInt (pages[10]?.answerNo) + 1,
      10: parseInt (pages[11]?.answerNo) + 1,
      11: parseInt (pages[12]?.answerNo) + 1,
      12: parseInt (pages[13]?.answerNo) + 1,
      13: parseInt (pages[14]?.answerNo) + 1,
      14: parseInt (pages[15]?.answerNo) + 1,
      15: parseInt (pages[16]?.answerNo) + 1,
      16: parseInt (pages[17]?.answerNo) + 1,
      17: parseInt (pages[18]?.answerNo) + 1,
      18: pages[19] && pages[19]?.answerNo ? parseInt (pages[19]?.answerNo) + 1 : 1,
      19: parseInt (pages[20]?.answerNo) + 1,
      20: parseInt (pages[21]?.answerNo) + 1,
      // Desactivate pannel from jelle calculation
      // 21:
      //   pages[23] && pages[23].aantal_zonnepanelen
      //     ? pages[23].aantal_zonnepanelen
      //     : 0,
      21: 0,
      e23: enery_values?.elektriciteitsverbruik || 0,
      g23: enery_values?.aardgasverbruik || 0,
      p23: enery_values?.zonnepanelen || 0,
    }
  }else{
    return null;
  }

}

export function getAdviceText(
  setState:any,
  status:any,
  title:any,
  pageNo:any,
  answerNo:any,
  selectedOption:any,
) {
  setState((st:any) => ({
    ...st,
    loading: true,
  }))
  if(pageNo == 30){title='Vergroenen'}
  // console.log("check for new text",status, title, answerNo,selectedOption)
  const adviceKey = getAdviceKey(status.key, title, answerNo, selectedOption)
  const adviceKeyXL = getAdviceKeyXL(status.key, title, answerNo, selectedOption)

  // console.log("status",status)
  // console.log("answerNo",answerNo)  
  // console.log("selectedOption",selectedOption)
  // console.log("title",title)
  // console.log("adviceKey",adviceKey)
  // console.log("adviceKeyXL",adviceKeyXL)
  // console.log("check for new text",adviceKey)

  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/page/9`)
    .then((response) => {
      let data = response.data[0].pageContent
      const dataXL = data.filter((d:any) => d.key == adviceKeyXL)
      data = data.filter((d:any) => d.key == adviceKey)
      if (data.length) {
        setState((st:any) => ({
          ...st,
          adviceContent: data[0].value,
          loading: false,
        }))
      }
      if (dataXL.length) {
        setState((st:any) => ({
          ...st,
          adviceContentXL: dataXL[0].value,
          loading: false,
        }))
      }
    })
    .catch((err) => {
      console.log(err)
      setState((st:any) => ({
        ...st,
        loading: false,
      }))
    })
}
export function parseEnergy(response:any, type:any) {
  // Change for addition of parsedEnergy
  // console.log(response?.estimatedSavings?.gas,'response?.estimatedSavings?.gas')
  if (type == 0) {
    return response?.estimatedSavings?.gas
  }
  return response?.estimatedSavings?.elec
}

export function parseRate(response:any, type:any) {
  // change for return one or another one
  // console.log(response,'response in parseRate')
  // console.log(response?.[0]?.value,'response?.data?.data[0][2]?.value')
  // console.log(type,'type in parseRate')
  if (type == 0) {
    return response?.[2]?.value
  }
  if (type == 1) {
    return response?.[0]?.value
  }
  return response?.[1]?.value
}


